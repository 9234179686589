import { Badge, Group, Stack, Text, rem } from '@mantine/core';
import { PathwayContext } from '../PathwayContext';
import { StartButton } from './StartPatent';
import React, { useContext } from 'react';
import classes from './style.module.css';
import { RFC } from '@app/ui/type';


export const Header : RFC = () => {

    const { pathway  } = useContext(PathwayContext);

    return (
        <Stack className={classes.header}>
            <Text fz={rem(24)}>
                {pathway.description}
            </Text>
            <Group>
                <Badge
                    variant='outline'
                    size='xl'
                    color='teal'>
                    {'IP Law'}
                </Badge>
            </Group>
            <StartButton />
        </Stack>
    );
};
