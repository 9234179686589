import { MarkdownText } from '@app/ui/control/MarkdownText';
import { SectionFormattedContentProps } from '../type';
import { Text, rem } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const SectionFormattedContent : RFC<SectionFormattedContentProps> = ({
    text,
}) =>
    <Text fz={rem(20)}>
        <MarkdownText>
            {text}
        </MarkdownText>
    </Text>;
