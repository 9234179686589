import { MetricCriteria } from './MetricCriteria';
import { MetricCriteriaListProps } from './type';
import { Box } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const MetricCriteriaList : RFC<MetricCriteriaListProps> = ({
    criteriaList,
}) =>
    <Box>
        {criteriaList.map((criteria, index) =>
            <MetricCriteria
                isLastItem={index === criteriaList.length - 1}
                key={`${criteria.hash}-${index}`}
                criteria={criteria} />,
        )}
    </Box>;
