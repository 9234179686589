import { HorizontalSpacer } from '@app/ui/layout/HorizontalSpacer';
import ImageStudentStudy from '@app/asset/image/student-study.png'
import { FeatureSection } from '@app/ui/marketing/FeatureSection';
import ImageAiRobot from '@app/asset/image/ai-robot.png';
import React, { useContext } from 'react';
import classes from './style.module.css';
import { App } from '@app/ui/AppContext';
import { Stack } from '@mantine/core';
import { RFC } from '@app/ui/type';
import Image from 'next/image';


export const Static : RFC = () => {
    const { isTablet } = useContext(App);

    /* eslint-disable max-len */

    return (
        <Stack className={classes.milestone}>

            <FeatureSection
                side='right'
                mediaComponent={
                    <Image
                        src={ImageAiRobot.src}
                        hidden={isTablet}
                        alt='AI Robot'
                        height={300}
                        width={300} />
                }
                subtitle='The AI Assisted Patent Partner simplifies patent creation for all, making it accessible to everyone regardless of skill level. By streamlining the process and overcoming skill barriers, it empowers inventors and legal professionals alike to navigate the patent creation journey with ease.'
                footer='The AI Assisted Patent Partner simplifies patent creation, making it accessible to all skill levels. By streamlining the process and overcoming barriers, it empowers both inventors and legal professionals to navigate the journey effortlessly.'
                title='AI ASSISTED PATENT PARTNER'
                itemList={[{
                    title    : 'Accessible Patent Assistance',
                    subtitle : 'Makes patent completion accessible to everyone',
                }, {
                    title    : 'Streamlined Process for All',
                    subtitle : 'Simplifies patent creation for inventors and legal assistance',
                }, {
                    title    : 'Overcoming Skill Barriers',
                    subtitle : 'No expertise required to begin the process',
                }]} />
            <HorizontalSpacer size='sm' />
            <FeatureSection
                side='left'
                mediaComponent={
                    <Image
                        src={ImageStudentStudy.src}
                        alt='Student Studying'
                        hidden={isTablet}
                        height={300}
                        width={300} />
                }
                title='BENEFITS TO STUDENTS & INVENTORS'
                subtitle='AttorneyAI offers students and inventors guided exploration and independent learning, fostering confidence in patent creation. With accessible resources and proactive engagement prompts, it streamlines the process and encourages collaboration with professionals when needed.'
                footer='AttorneyAI fosters guided exploration and independent learning for students and inventors, instilling confidence in patent creation. With accessible resources and proactive engagement prompts, it streamlines the process and facilitates collaboration with professionals as required.'
                itemList={[{
                    title    : 'Guided Exploration',
                    subtitle : 'Provides guidance for students exploring patent creation.',
                }, {
                    title    : 'Independent Learning',
                    subtitle : 'Supports students in exploring on their own.',
                }, {
                    title    : 'Proactive Engagement',
                    subtitle : 'Encourages contacting professionals when ready or experienced.',
                }, {
                    title    : 'Accessible Resources',
                    subtitle : 'Offers resources for students of any background or skill level.',
                }, {
                    title    : 'Confidence Building',
                    subtitle : 'Helps students gain confidence in patent creation process.',
                }]} />
        </Stack>
    );

    /* eslint-enable max-len */
};
