import { CA } from '@app/type/framework/core/CoreAction';
import { PathwayContext } from '../PathwayContext';
import { Button, rem } from '@mantine/core';
import { useDispatch } from 'react-redux';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import { Action } from '@app/client/action';


export const StartButton : RFC = () => {

    const dispatch = useDispatch();

    const { pathway } = useContext(PathwayContext);

    const handleClick = () : CA => dispatch(Action.startPathway(pathway));

    return (
        <Button
            aria-label='Start Your Patent'
            onClick={handleClick}
            w={rem(450)}
            size='xl'
            mt='xl'>
            {'Start Your Patent'}
        </Button>
    );
};
