import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { PatentFigureModel } from '@app/type/model/PatentFigureModel';


export const DefaultPatentFigure : PatentFigureModel = {
    ...DefaultCoreModel,
    modelType       : ModelType.PatentFigure,
    referenceNumber : '',
    caption         : '',
    url             : '',
};

export const MakePatentFigure = (
    overrides : Partial<PatentFigureModel>,
) : PatentFigureModel => ({
    ...DefaultPatentFigure,
    ...overrides,
});
