import { RelationshipModel } from '@app/type/model/RelationshipModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { ScreenSize } from '@app/constant/framework/ScreenSize';
import { MilestoneModel } from '@app/type/model/MilestoneModel';
import { PathwayModel } from '@app/type/model/PathwayModel';
import { AssetModel } from '@app/type/model/AssetModel';
import { ChatModel } from '@app/type/model/ChatModel';
import { TaskModel } from '@app/type/model/TaskModel';


// These belong in selctors and need code consolidation

const getPathwayMilestoneList = (
    pathway          : PathwayModel,
    lookupMilestone  : Record<string, MilestoneModel>,
    relationshipList : RelationshipModel[],
) : MilestoneModel[] => {

    const pathwayMilestoneRelationshipList = relationshipList.filter(relationship =>
        relationship.relationshipType   === RT.BelongsTo
        && relationship.destModelHash   === pathway.hash
        && relationship.destModelType   === ModelType.Pathway
        && relationship.sourceModelType === ModelType.Milestone)

    return  pathwayMilestoneRelationshipList.map(
        milestoneRelationship => lookupMilestone[milestoneRelationship.sourceModelHash!])
        .sort((a, b) => a.order - b.order);
};

const getPathwayTaskList = (
    pathway          : PathwayModel,
    lookupTask       : Record<string, TaskModel>,
    relationshipList : RelationshipModel[],
) : TaskModel[] => {

    const pathwayTaskRelationshipList = relationshipList.filter(relationship =>
        relationship.relationshipType   === RT.BelongsTo
        && relationship.destModelHash   === pathway.hash
        && relationship.destModelType   === ModelType.Pathway
        && relationship.sourceModelType === ModelType.Task)

    return  pathwayTaskRelationshipList.map(
        taskRelationship => lookupTask[taskRelationship.sourceModelHash!])
        .sort((a, b) => a.order - b.order);
};

const getPathwayChatList = (
    pathway          : PathwayModel,
    lookupChat       : Record<string, ChatModel>,
    relationshipList : RelationshipModel[],
) : ChatModel[] => {

    const pathwayChatRelationshipList = relationshipList.filter(relationship =>
        relationship.relationshipType   === RT.BelongsTo
        && relationship.destModelHash   === pathway.hash
        && relationship.destModelType   === ModelType.Pathway
        && relationship.sourceModelType === ModelType.Chat)

    return  pathwayChatRelationshipList.map(
        taskRelationship => lookupChat[taskRelationship.sourceModelHash!])
        .sort((a, b) => a.chatOrder - b.chatOrder);
};

const getPathwayAssetList = (
    pathway          : PathwayModel,
    lookupAsset      : Record<string, AssetModel>,
    relationshipList : RelationshipModel[],
) : AssetModel[] => {

    const pathwayAssetRelationshipList = relationshipList.filter(relationship =>
        relationship.relationshipType   === RT.AttachedTo
        && relationship.destModelHash   === pathway.hash
        && relationship.destModelType   === ModelType.Pathway
        && relationship.sourceModelType === ModelType.Asset)

    return  pathwayAssetRelationshipList.map(
        taskRelationship => lookupAsset[taskRelationship.sourceModelHash!]);
};


const toDotDotDot = (
    possiblyLongString : string,
    maxLen             : number = 30,
) : string => {
    if (possiblyLongString.length > maxLen)
        return possiblyLongString.substring(0, maxLen) + '...';

    return possiblyLongString;
};


const toFormattedDate = (
    date : string | null | undefined,
) : string => {

    if(date === null || date === undefined)
        return 'Invalid Date';

    const dateObj = new Date(date);

    const formattedDate =  dateObj.toLocaleString('en-US', {
        day    : 'numeric',
        year   : 'numeric',
        month  : 'short',
        hour12 : true,
    });

    const formattedTime =  dateObj.toLocaleString('en-US', {
        minute : 'numeric',
        hour   : 'numeric',
        hour12 : true,
    })

    return `${formattedDate} at ${formattedTime}`;
}

const screenSizeCol = (
    screenSize : ScreenSize,
    phone      : number,
    tablet     : number,
    desktop    : number,
) : number => {
    switch (screenSize) {
        case ScreenSize.Desktop : return desktop;
        case ScreenSize.Tablet  : return tablet;
        case ScreenSize.Phone   : return phone;
    }
};

export const UtilityUI = {
    getPathwayMilestoneList,
    getPathwayAssetList,
    getPathwayTaskList,
    getPathwayChatList,
    toFormattedDate,
    screenSizeCol,
    toDotDotDot,
};
