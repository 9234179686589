import { DefaultSortFilterConfig } from '@app/ui/list/PathwayList/constant';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { BrowseModelLayout } from '@app/ui/browse/BrowseModelLayout';
import { SortPathwayHeader } from '../PathwayList/SortPathwayHeader';
import { SortFilterConfig } from '@app/ui/list/PathwayList/type.jsx';
import { updateComposeModelAction } from '@app/client/CommonAction';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { selectPageTask } from '@app/client/selector/PageSelector';
import { useDispatch, useSelector } from '@app/client/hook';
import { CA } from '@app/type/framework/core/CoreAction';
import { TaskModel } from '@app/type/model/TaskModel';
import { Spacer } from '@app/ui/layout/Spacer';
import { Action } from '@app/client/action';
import { Text, rem } from '@mantine/core';
import { Task } from '@app/ui/model/Task';
import React, { useState } from 'react';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const TaskList : RFC<Props> = ({
    pathwayMilestoneList = [],
    relationshipConfig = {},
    showRowContent,
    showSortFilter = true,
    taskList,
    pathway = null,
    title = 'Tasks',
}) => {

    const { dialogModalStateType, model } = useSelector(selectPageTask);

    const dispatch = useDispatch();

    const handleChangeCompose = (newTask : TaskModel) : CA =>
        dispatch(updateComposeModelAction({
            ...newTask,
            order : taskList.length,
        }));

    const handleNewModal = () : CA =>
        dispatch(Action.openNewModelDialog(ModelType.Task));

    const [sortFilterConfig, setSortFilterConfig] =
        useState<SortFilterConfig>(DefaultSortFilterConfig);

    const handleSortFilterConfigChange = (config : SortFilterConfig) : void =>
        setSortFilterConfig(config);

    return (
        <BrowseModelLayout
            dialogModalStateType={dialogModalStateType}
            relationshipConfig={relationshipConfig}
            newButtonContent='Create a Task'
            onNewModal={handleNewModal}
            modelType={ModelType.Task}
            composingModel={model}
            modelList={taskList}
            title={title}
            headerComponent={showSortFilter &&
                <SortPathwayHeader
                    onChange={handleSortFilterConfigChange}
                    config={{
                        ...sortFilterConfig,
                        filter : null,
                    }} />
            }
            noItemsContent={
                <Text
                    fz={rem(32)}
                    fs='italic'
                    c='dimmed'
                    fw={200}>
                    {'- No Tasks -'}
                </Text>
            }
            renderItem={item =>
                <>
                    <Task
                        pathwayMilestoneList={pathwayMilestoneList}
                        showRowContent={showRowContent}
                        mode={ControlModeType.Row}
                        value={item as TaskModel}
                        pathway={pathway} />
                    <Spacer showLine={false} />
                </>
            }
            renderCompose={
                <Task
                    pathwayMilestoneList={pathwayMilestoneList}
                    showRowContent={showRowContent}
                    onChange={handleChangeCompose}
                    mode={ControlModeType.Create}
                    pathway={pathway}
                    value={model} />
            } />
    );
};
