import { PathwayContext } from '@app/ui/model/Pathway/PathwayContext';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { TaskList } from '@app/ui/list/TaskList';
import { RelationshipConfig } from '@app/type/model/RelationshipConfig';
import { RFC } from '@app/ui/type';
import { useContext } from 'react';


export const PathwaySettingsTask : RFC = () => {

    const { pathway, pathwayMilestoneList, pathwayTaskList } = useContext(PathwayContext);

    const relationshipConfig : RelationshipConfig = {
        [RT.BelongsTo] : pathway,
    };

    return (
        <TaskList
            pathwayMilestoneList={pathwayMilestoneList}
            relationshipConfig={relationshipConfig}
            taskList={pathwayTaskList}
            showRowContent={true}
            pathway={pathway} />
    );
};
