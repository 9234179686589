import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { DefaultDocument } from '@app/constant/default/DefaultDocument';
import { selectLookupAsset } from '@app/client/selector/ListSelector';
import { WorkspaceAssetList } from '../WorkspaceAssetList';
import { AssetModel } from '@app/type/model/AssetModel';
import { useSelector } from '@app/client/hook';
import { type TabAssetProps } from '../type';
import { Asset } from '@app/ui/model/Asset';
import { RFC } from '@app/ui/type';


export const TabAsset : RFC<TabAssetProps> = ({
    selectedAssetHash,
}) => {
    const lookupAsset = useSelector(selectLookupAsset);

    if (!selectedAssetHash)
        return <WorkspaceAssetList />;

    const selectedAsset = lookupAsset[selectedAssetHash] as AssetModel;

    return <Asset
        mode={ControlModeType.Create}
        document={DefaultDocument}
        showManageControls={true}
        relationshipList={[]}
        value={selectedAsset} />;
};
