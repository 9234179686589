import { selectLookupMilestone, selectRelationshipList } from '@app/client/selector/ListSelector';
import { PathwayTimeline } from '@app/ui/static/PathwayTimeline';
import { ModelViewHeader } from '@app/ui/layout/ModelViewHeader';
import { WorkspaceContext } from './WorkspaceContext';
import { Box, Container, Grid } from '@mantine/core';
import { TabList } from '@app/ui/control/TabList';
import { useSelector } from '@app/client/hook';
import { UtilityUI } from '@app/ui/UtilityUI';
import { FcDocument } from 'react-icons/fc';
import { WorkspaceViewProps } from './type';
import { breadCrumbList } from './constant';
import { getTabsConfig } from './Utility';
import { App } from '@app/ui/AppContext';
import { getSubTitle } from './Logic';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const WorkspaceView : RFC<WorkspaceViewProps> = ({
    showHeader,
}) => {
    const {
        selectedMilestoneHash : _selectedMilestoneHash, pathway, userType, defaultTab,
    } = useContext(WorkspaceContext);

    const { isTablet } = useContext(App);
    const tabsConfig = getTabsConfig(pathway);

    let extraSpace = 0;

    const showtimeline = false; // shouldShowTimeline(userType);

    const lookupMilestone  = useSelector(selectLookupMilestone);
    const relationshipList = useSelector(selectRelationshipList);

    const _pathwayMilestoneList = UtilityUI.getPathwayMilestoneList(
        pathway, lookupMilestone, relationshipList);

    if(!showtimeline)
        extraSpace = 3;

    return (
        <Container fluid={true}>
            {showHeader &&
                <Box pb='lg'>
                    <ModelViewHeader
                        subtitle={getSubTitle(userType, pathway)}
                        currentBreadCrumbs={breadCrumbList}
                        icon={<FcDocument size={60} />}
                        title={pathway.title}
                        model={pathway} />
                </Box>
            }
            <Grid>
                <Grid.Col span={isTablet ? 12 : 9 + extraSpace}>
                    <TabList
                        defaultTab={defaultTab}
                        tabsConfig={tabsConfig} />
                </Grid.Col>
                {showtimeline &&
                    <Grid.Col span={isTablet ? 12  : 3}>
                        <br />
                        <br />
                        <PathwayTimeline />
                    </Grid.Col>
                }
            </Grid>
        </Container>
    );
};
