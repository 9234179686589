import { NumberedField } from './NumberedField';
import { SectionCenteredProps } from '../type';
import { Text, rem } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const SectionCentered : RFC<SectionCenteredProps> = ({
    text, fieldNumber,
}) =>
    <NumberedField fieldNumber={fieldNumber}>
        <Text
            mr={rem(80)}
            fz={rem(22)}
            ta='center'
            w='100%'
            fw={500}>
            {text}
        </Text>
    </NumberedField>;
