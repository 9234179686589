import { Container, Grid, rem } from '@mantine/core';
import { FloatingBanner } from './FloatingBanner';
import React, { useContext } from 'react';
import classes from './style.module.css';
import { App } from '@app/ui/AppContext';
import { Syllabus } from './Syllabus';
import { Section } from './Section';
import { RFC } from '@app/ui/type';
import { Footer } from './Footer';
import { Header } from './Header';
import { Static } from './Static';


export const PathwayPublished : RFC = () => {
    const { isDesktop, isMobile, isAuth } = useContext(App);

    return (
        <Container
            className={classes.contentContainer}
            ml={isAuth ? 'md' : rem(200) }
            mr={isAuth ? 'md' : rem(200) }
            fluid={true}>
            <Grid className={classes.debugGrid}>
                <Grid.Col
                    className={classes.debugGridCol}
                    span={isDesktop ? 6 : 12}>
                    <Section>
                        <Header />
                    </Section>
                </Grid.Col>
                <Grid.Col span={6}>
                    <FloatingBanner />
                </Grid.Col>
                <Grid.Col
                    className={classes.debugGridCol}
                    span={12}>
                    <Section>
                        {/* <Kanban /> */}
                    </Section>
                </Grid.Col>
                <Grid.Col
                    className={classes.debugGridCol}
                    span={isMobile ?  12 : 6}>
                    <Section>
                        <Syllabus />
                    </Section>
                </Grid.Col>
                <Grid.Col
                    className={classes.debugGridCol}
                    span={isMobile ? 12 : 6}>
                    <Section>
                        <Static />
                    </Section>
                </Grid.Col>
                <Grid.Col
                    className={classes.debugGridCol}
                    span={12}>
                    <Section>
                        <Footer />
                    </Section>
                </Grid.Col>
            </Grid>
        </Container>
    );
};
