import { CoreModel } from '@app/type/framework/core/CoreModel';
import { MantineSize } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { CA } from '@app/type/framework/core/CoreAction';


export enum EditFieldType {
    TextArea = 'TextArea',
    TextBox  = 'TextBox',
}

export const enum KeyCode {
    Enter = 'Enter',
}

export type Props<T extends CoreModel> = PropsWithChildren<{
    onChange  : (name : string, value : string) => CA;
    model    ?: T;
    value     : string;
    type      : EditFieldType;
    name      : string;
    size      : MantineSize;
}>;
