import { LabelValue } from './LabelValue';
import { Table, Text, rem } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { NumberedField } from './NumberedField';
import { SectionInventorProps } from '../type';


export const SectionInventor : RFC<SectionInventorProps> = ({
    inventorList, fieldNumber,
}) =>
    <NumberedField fieldNumber={fieldNumber}>
        <LabelValue
            leftSection={
                <Text
                    fz={rem(22)}>
                    {'Inventors:'}
                </Text>
            }
            rightSection={

                <Table>
                    <Table.Tbody>
                        {inventorList.map(inventor =>
                            <Table.Tr key={inventor.name}>
                                <Table.Td>
                                    <Text
                                        ta='right'
                                        fw={600}
                                        mr={rem(10)}
                                        fz={rem(24)}>
                                        {inventor.name}
                                    </Text>
                                </Table.Td>
                                <Table.Td>
                                    <Text
                                        fz={rem(16)}
                                        ta='left'>
                                        {inventor.location}
                                    </Text>
                                </Table.Td>
                            </Table.Tr>,
                        )}
                    </Table.Tbody>
                </Table>

            } />
    </NumberedField>
    ;


