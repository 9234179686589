import { useDebouncedValue } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { Textarea } from '@mantine/core';
import { Props } from './type';
import { RFC } from '@app/ui/type';


export const TextareaDebounced : RFC<Props> = ({
    onChange, value, label, name,
    milliseconds = 3000, onBlur,
}) => {
    const [text, setText] = useState(value);
    const [debounced] = useDebouncedValue(text, milliseconds);

    useEffect(() => {
        if (debounced !== value)
            onChange(debounced, name);

    }, [debounced, name, onChange, value]);

    const handleBlurInput = () : void => {
        onBlur(text, name);
    };

    const handleChange = (evt : React.ChangeEvent<HTMLTextAreaElement>) : void => {
        setText(evt.currentTarget.value);
    };

    return (
        <Textarea
            onBlur={handleBlurInput}
            onChange={handleChange}
            label={label}
            value={text} />
    );
}
