import { PatentClassificationSystem } from '../enum/model/PatentClassificationSystemEnum';
import { PatentTransitionalPhrase } from '../enum/model/PatentTransitionalPhraseEnum';
import { MakePatentClaimDefinition } from '../default/DefaultPatentClaimDefinition';
import { MakePatentClassification } from '../default/DefaultPatentClassification';
import { MakePatentDescription } from '../default/DefaultPatentDescription';
import { MakePatentLimitation } from '../default/DefaultPatentLimitation';
import { MakePatentParagraph } from '../default/DefaultPatentParagraph';
import { MakePatentInventor } from '../default/DefaultPatentInventor';
import { MakePatentSection } from '../default/DefaultPatentSection';
import { PatentClaimType } from '../enum/model/PatentClaimTypeEnum';
import { MakePatentFigure } from '../default/DefaultPatentFigure';
import { MakePatentNumber } from '../default/DefaultPatentNumber';
import { MakePatentClaim } from '../default/DefaultPatentClaim';
import { MakePatent } from '../default/DefaultPatent';


/* eslint-disable max-len */

// an extensive depiction of the ray gun invention as  patent model, using Make* functions
export const MockPatentModel = MakePatent({
    classificationList : [
        MakePatentClassification({
            subclass : 'F41H',
            subgroup : '13',
            section  : 'F',
            class    : '41',
            group    : '13',
        }),
        MakePatentClassification({
            subclass : 'H01S',
            subgroup : '300',
            section  : 'H',
            class    : '01',
            group    : '3',
        }),
        MakePatentClassification({
            system   : PatentClassificationSystem.USPC,
            subclass : 'G02B',
            subgroup : '600',
            section  : 'G',
            class    : '02',
            group    : '6',
            version  : '2006.1',
        }),
        MakePatentClassification({
            system   : PatentClassificationSystem.USPC,
            subclass : 'G02B',
            subgroup : '600',
            section  : 'G',
            class    : '02',
            group    : '6',
            version  : '2006.1',
        }),
        MakePatentClassification({
            system   : PatentClassificationSystem.IPC,
            subclass : 'A',
            subgroup : '10',
            section  : 'A',
            class    : '10',
            group    : '10',
            version  : null,
        }),
        MakePatentClassification({
            system   : PatentClassificationSystem.IPC,
            subclass : 'A',
            subgroup : '10',
            section  : 'A',
            class    : '10',
            group    : '10',
            version  : null,
        }),
    ],

    publicationNumber : MakePatentNumber({
        countryCode : 'US',
        serial      : '736546',
        year        : '2022',
        kind        : 'A1',

    }),

    applicationNumber : MakePatentNumber({
        countryCode : 'US',
        serial      : '234324',
        year        : '2021',
        kind        : 'A1',
    }),
    usClassification : 'F41H 13/00; H01S 3/00',
    publicationDate  : '2022-05-05',

    descriptionList : [
        MakePatentDescription({
            text : 'The device utilizes a high-efficiency thermoelectric generator for power generation, converting waste heat from the laser diode into usable electrical energy, thus extending operational duration.',
        }),
        MakePatentDescription({
            text : 'A microelectromechanical system (MEMS) based beam-steering mechanism allows for precise control of the emitted laser beam direction and focus, enabling accurate targeting and minimizing collateral effects.',
        }),
        MakePatentDescription({
            text : 'An integrated sensor suite incorporating LIDAR and thermal imaging provides real-time environmental data and target information, enhancing situational awareness and targeting accuracy.',
        }),
    ],
    inventorList : [
        MakePatentInventor({
            location : 'Santa Clara, CA',
            name     : 'Dr. Evelyn Chen',
        }),
        MakePatentInventor({
            location : 'Cambridge, MA',
            name     : 'Dr. David Kim',
        }),
        MakePatentInventor({
            location : 'Austin, TX',
            name     : 'Dr. Maria Rodriguez',
        }),
        MakePatentInventor({
            location : 'Seattle, WA',
            name     : 'Dr. Michael Patel',
        }),
        MakePatentInventor({
            location : 'Boulder, CO',
            name     : 'Dr. Sarah Wilson',
        }),
    ],
    addressLine1 : '500 Innovation Parkway',
    addressLine2 : 'Tech Hub',
    addressLine3 : 'Silicon Valley, CA 94025',
    addressLine4 : 'United States',
    description  : 'A sophisticated photon projection device commonly referred to as a ray gun, capable of emitting concentrated beams of light with variable intensity. The device incorporates a novel photonic crystal that amplifies light into a high-energy beam, allowing for precision targeting and environmental adaptability. The ray gun is designed for non-lethal crowd control and precision object disruption, featuring advanced targeting systems and environmental adaptability features.',
    sectionList  : [
        MakePatentSection({
            title         : 'TECHNICAL FIELD',
            paragraphList : [
                MakePatentParagraph({
                    referenceId : 2,
                    text        : 'The present invention lies within the domain of photonics and directed energy systems, specifically focusing on non-lethal applications for crowd control and precision object manipulation. The core technology revolves around a novel photonic crystal capable of amplifying light sources into highly focused, coherent beams of energy. This allows for adjustable intensity and targeted application, minimizing collateral damage and unintended consequences.',
                }),
                MakePatentParagraph({
                    referenceId : 3,
                    text        : 'Further advancements include the integration of sophisticated targeting systems incorporating gyroscopic stabilization and AI-driven predictive algorithms. These systems ensure exceptional accuracy and real-time adjustments, taking into account environmental factors and target movement.',
                }),
            ],
        }),
        MakePatentSection({
            title         : 'SUMMARY',
            paragraphList : [
                MakePatentParagraph({
                    referenceId : 1,
                    text        : 'The present invention relates to a novel directed energy device, hereinafter referred to as the "Precision Energy Projector" (PEP). The PEP is designed with a focus on precision targeting and adaptability to diverse operational environments. It utilizes a proprietary photonic crystal amplification system to generate a highly focused, coherent energy beam with adjustable intensity and focal points.',
                }),
                MakePatentParagraph({
                    referenceId : 4,
                    text        : 'Furthermore, the PEP is equipped with advanced targeting systems leveraging gyroscopic stabilization and AI-powered predictive algorithms. These features enhance accuracy and real-time adaptation to target movement and environmental changes. An array of environmental adaptability features, including thermal regulation, dust filtration, and water resistance, ensures reliable performance under diverse operational conditions.',
                }),
            ],
        }),
        MakePatentSection({
            title         : 'Background',
            paragraphList : [
                MakePatentParagraph({
                    referenceId : 3,
                    text        : 'Conventional directed energy systems often face limitations in terms of energy efficiency, beam coherence, and adaptability to various environments. Existing solutions primarily rely on bulky and complex laser generation systems with limited portability and operational range. Moreover, targeting accuracy and safety mechanisms often fall short of the requirements for non-lethal applications.',
                }),
                MakePatentParagraph({
                    referenceId : 7,
                    text        : 'The modular design of the device facilitates easy maintenance and component replacement, minimizing downtime and contributing to a prolonged operational lifespan. This modularity also allows for future upgrades and customization to meet evolving operational requirements.',
                }),
            ],
        }),
    ],
    latinPhrase : 'Lumen de Luce',
    filingDate  : '2021-01-01',
    claimList   : [
        MakePatentClaim({
            transitionalPhrase : PatentTransitionalPhrase.Comprising,
            amendmentDetails   : '',
            referencedClaims   : [],
            hasBeenAmended     : false,
            definitionList     : [
                MakePatentClaimDefinition({
                    definition : 'A portable, handheld device capable of emitting a concentrated beam of electromagnetic radiation with adjustable intensity and focal point.',
                    term       : 'Precision Energy Projector (PEP)',
                }),
                MakePatentClaimDefinition({
                    definition : 'A solid-state light amplification system utilizing a photonic crystal structure to enhance the energy output of the emitted beam.',
                    term       : 'Photonic Crystal Amplification System',
                }),
                MakePatentClaimDefinition({
                    definition : 'A system incorporating gyroscopic stabilization and AI-powered predictive algorithms to ensure precise targeting and real-time adjustments based on target movement and environmental factors.',
                    term       : 'Adaptive Targeting System',
                }),
            ],
            limitationList : [
                MakePatentLimitation({
                    title       : 'Limitation concerning environmental adaptability',
                    description : 'The PEP is equipped with a suite of environmental adaptability features, including active thermal regulation, a dust filtration system, and water-resistant construction, enabling reliable operation in diverse environments and weather conditions.',
                }),
            ],
            claimNumber : 1,
            claimType   : PatentClaimType.Apparatus,
            preamble    : 'A precision energy projection apparatus',
            claim       : '1. A precision energy projection apparatus comprising: a Precision Energy Projector (PEP) configured to emit a concentrated beam of electromagnetic radiation; a Photonic Crystal Amplification System for enhancing the energy output of the emitted beam; and an Adaptive Targeting System for precise targeting and real-time adjustments, wherein the PEP is further equipped with a suite of environmental adaptability features.',
        }),
        MakePatentClaim({
            transitionalPhrase : PatentTransitionalPhrase.Comprising,
            amendmentDetails   : '',
            referencedClaims   : [],
            hasBeenAmended     : false,
            definitionList     : [
                // ... existing definitions ...
            ],
            limitationList : [
                MakePatentLimitation({
                    title       : 'Limitation concerning environmental adaptability',
                    description : 'The PEP incorporates a suite of environmental adaptability features, including active thermal regulation for maintaining optimal operational temperatures, a dust filtration system for preventing performance degradation in harsh environments, and water-resistant construction for ensuring reliable functionality under various weather conditions.',
                }),
                MakePatentLimitation({
                    title       : 'Limitation regarding safety measures during operation',
                    description : 'The PEP is designed with multiple safety features, including fail-safe mechanisms to prevent accidental discharge, user authentication protocols to restrict access and prevent unauthorized use, and a redundant safety interlock system for added protection.',
                }),
                MakePatentLimitation({
                    title       : 'Limitation on energy projection methods',
                    description : 'The method of energy projection utilizes a photonic crystal-based amplification system to generate a highly focused, coherent energy beam. The intensity and focal point of the beam are adjustable, allowing for precise targeting and controlled application of energy.',
                }),
                MakePatentLimitation({
                    title       : 'Limitation on targeting systems',
                    description : 'The targeting system integrates gyroscopic stabilization for maintaining beam stability and AI-powered predictive algorithms for real-time adjustments based on target movement and environmental factors, ensuring exceptional accuracy and precision.',
                }),
            ],
            claimNumber : 2,
            claimType   : PatentClaimType.Method,
            preamble    : 'A method for precise energy projection',
            claim       : '2. A method for precise energy projection comprising: generating a concentrated beam of electromagnetic radiation using a photonic crystal-based amplification system; adjusting the intensity and focal point of the emitted beam; and utilizing an adaptive targeting system with gyroscopic stabilization and AI-powered predictive algorithms for precise targeting and real-time adjustments, wherein the method further incorporates environmental adaptability features for operation in diverse conditions.',
        }),
    ],
    createdAt  : '2023-08-22',
    updatedAt  : '2023-11-15',
    createdBy  : 99, // Assuming an updated creator ID
    title      : 'Precision Energy Projector (PEP): A Novel Directed Energy Device',
    subtitle   : 'Patent Application Publication',
    assignee   : 'Quantum Dynamics Corporation',
    abstract   : 'The Precision Energy Projector (PEP) is a novel directed energy device designed for precise targeting and adaptability to various operational environments. It utilizes a proprietary photonic crystal amplification system and advanced targeting mechanisms for controlled energy projection with minimal collateral effects. The PEP incorporates a suite of safety features and environmental adaptability enhancements for reliable and secure operation in diverse settings.',
    country    : 'United States',
    barcode    : '876543210987', // Assuming an updated barcode
    figureList : [
        MakePatentFigure({
            referenceNumber : '1',
            caption         : 'Schematic overview of the ray gun',
            url             : 'https://example.com/raygun_overview.jpg',
        }),
        MakePatentFigure({
            referenceNumber : '2',
            caption         : 'Detailed view of the targeting system and stabilization module',
            url             : 'https://example.com/raygun_targeting.jpg',
        }),
        MakePatentFigure({
            referenceNumber : '3',
            caption         : 'Internal components of the ray gun, showing the photonic crystal and energy source',
            url             : 'https://example.com/raygun_components.jpg',
        }),
        MakePatentFigure({
            referenceNumber : '4',
            caption         : 'User interface of the ray gun, featuring touchscreen controls and voice activation',
            url             : 'https://example.com/raygun_interface.jpg',
        }),
    ],
});

/* eslint-enable max-len */

