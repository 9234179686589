import { Box, Group, rem } from '@mantine/core';
import { LabelValueProps } from '../type';
import { RFC } from '@app/ui/type';


export const LabelValue : RFC<LabelValueProps> = ({
    rightSection, leftSection,
}) =>
    <Group
        wrap='nowrap'
        align='top'
        gap={0}>
        <Box w={rem(100)}>
            {leftSection}
        </Box>
        <Box>
            {rightSection}
        </Box>
    </Group>;
