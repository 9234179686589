import { UserTypeToggle } from '@app/ui/control/UserTypeToggle';
import { ResponsiveHeaderProps } from '../type';
import { Group } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const ResponsiveHeader : RFC<ResponsiveHeaderProps> =({
    onScreenTypeChange : _onScreenTypeChange,
    onUserTypeChange,
    screenType : _screenType,
    userType,
}) =>
    <Group
        wrap='nowrap'
        justify='center'>
        <UserTypeToggle
            onChange={onUserTypeChange}
            value={userType} />
    </Group>
