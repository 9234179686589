import { Badge, Box, Group, Text, rem } from '@mantine/core';
import { MarkdownText } from '@app/ui/control/MarkdownText';
import { MetricCriteriaProps } from './type';
import { RFC } from '@app/ui/type';


export const MetricCriteria : RFC<MetricCriteriaProps> = ({
    criteria, isLastItem,
}) =>
    <Group
        justify='space-between'
        mt='md'
        pl='lg'
        style={{
            borderBottom : isLastItem
                ? 'none'
                : '1px dotted #ccc',
        }}
        wrap='nowrap'>
        <Box pt='md'>
            <Group>
                <Text
                    gradient={{ deg : 133, from : 'blue', to : 'cyan' }}
                    variant='gradient'
                    fz={rem(18)}
                    fw={800}>
                    {criteria.label}
                </Text>
                <Badge
                    size='xs'
                    variant='outline'>
                    {'CRITERIA'}
                </Badge>
            </Group>
            <Box fz='xl'>
                <MarkdownText>
                    {criteria.analysis}
                </MarkdownText>
            </Box>
        </Box>
        <Box>
            <Text
                fz={rem(56)}
                c='blue'
                fw={200}>
                {criteria.grade}
            </Text>
        </Box>
    </Group>;
