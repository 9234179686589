import { Group, Title, rem, Stack, Grid, Text, Box } from '@mantine/core'
import { RelationshipConfig } from '@app/type/model/RelationshipConfig';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { CloseDocumentButton } from './CloseDocumentButton';
import { ModelChat } from '@app/ui/control/ModelChat';
import { PathwayContext } from '../PathwayContext';
import { WorkspaceDocumentProps } from '../type';
import { useDispatch } from '@app/client/hook';
import { Action } from '@app/client/action';
import { Format } from '@app/shared';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const WorkspaceDocument : RFC<WorkspaceDocumentProps> = ({
    asset,
}) => {

    const { pathway, document } =  useContext(PathwayContext);

    const dispatch = useDispatch();

    const handleClickCloseDocument = () : void => {
        dispatch(Action.selectPathwayAsset(null));
    };

    const relationshipConfig : RelationshipConfig = {
        [RT.EnrolledIn] : pathway,
        [RT.BelongsTo ] : asset,
    };

    const fileInfo = Format.toFriendlyFileInfo({
        size : document?.fileSize || 0,
        type : document?.fileType || 'Unknown',
        name : document?.fileName || 'Unknown',
        date : document?.fileDate || 0,
        hash : null,
        path : '',
        data : '',
    });

    return (
        <Stack>
            <Group
                justify='space-between'
                pr='xl'>
                <Title
                    size={rem(40)}
                    order={2}
                    fw={400}
                    mb='xl'
                    pt={0}
                    mt={0}>
                    {asset.title}
                </Title>
                <CloseDocumentButton onClose={handleClickCloseDocument} />
            </Group>

            <Grid>
                <Grid.Col span={9}>
                    <ModelChat
                        relationshipConfig={relationshipConfig}
                        belongsToModel={asset}
                        isResearching={false}
                        chatList={[]} />
                </Grid.Col>
                <Grid.Col
                    span={3}
                    style={{ minHeight : rem(800)}}>
                    <Group justify='space-evenly'>
                        <Box>
                            <Text>
                                {fileInfo.date}
                            </Text>
                        </Box>
                        <Box>
                            <Text>
                                {`${fileInfo.size} ${fileInfo.type}`}
                            </Text>
                        </Box>
                    </Group>
                    <iframe
                        style={{ border : 'none' }}
                        src='https://dagrs.berkeley.edu/sites/default/files/2020-01/sample.pdf#toolbar=0&navpanes=0&scrollbar=0'
                        height='100%'
                        width='100%'>
                    </iframe>
                </Grid.Col>
            </Grid>
        </Stack>
    )
}
