import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { selectPageMilestone } from '@app/client/selector/PageSelector';
import { BrowseModelLayout } from '@app/ui/browse/BrowseModelLayout';
import { updateComposeModelAction } from '@app/client/CommonAction';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { useDispatch, useSelector } from '@app/client/hook';
import { RouteLink } from '@app/ui/control/RouteLink';
import { Milestone } from '@app/ui/model/Milestone';
import { Spacer } from '@app/ui/layout/Spacer';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import React from 'react';
import { CA } from '@app/type/framework/core/CoreAction';
import { MilestoneModel } from '@app/type/model/MilestoneModel';
import { Action } from '@app/client/action';


export const MilestoneList : RFC<Props> = ({
    milestoneList, relationshipConfig, onReorder,
}) => {

    const { dialogModalStateType, model } = useSelector(selectPageMilestone);

    const dispatch = useDispatch();

    const handleChangeCompose  = (newMilestone : MilestoneModel) : CA =>
        dispatch(updateComposeModelAction({
            ...newMilestone,
            order : milestoneList.length,
        }));

    const handleNewModal = () : CA =>
        dispatch(Action.openNewModelDialog(ModelType.Milestone));

    return (
        <BrowseModelLayout
            dialogModalStateType={dialogModalStateType}
            relationshipConfig={relationshipConfig}
            newButtonContent='Create a Milestone'
            modelType={ModelType.Milestone}
            onNewModal={handleNewModal}
            modelList={milestoneList}
            composingModel={model}
            onReorder={onReorder}
            title='Milestones'
            renderItem={item =>
                <RouteLink model={item}>
                    <Milestone
                        value={item as MilestoneModel}
                        mode={ControlModeType.Row}
                        showManageControls={true} />
                    <Spacer showLine={false} />
                </RouteLink>
            }
            renderCompose={
                <Milestone
                    relationshipConfig={relationshipConfig}
                    onChange={handleChangeCompose}
                    mode={ControlModeType.Create}
                    showManageControls={false}
                    value={model} />
            } />
    );
};
