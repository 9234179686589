import { WorkspaceTab } from '@app/constant/enum/ui/WorkspaceTabEnum';
import { WorkspaceContext } from './WorkspaceContext';
import { WorkspaceView } from './WorkspaceView';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import React from 'react';


export const Workspace : RFC<Props> = ({
    selectedMilestoneHash,
    selectedChatHash,
    pathwayMilestoneList,
    pathwayTaskList,
    defaultTab,
    showHeader = true,
    onChange,
    pathway,
    userType,
    mode : _mode,
}) => {

    defaultTab ||= WorkspaceTab.Tasks;

    return (
        <WorkspaceContext.Provider
            value={{
                selectedMilestoneHash,
                pathwayMilestoneList,
                selectedChatHash,
                pathwayTaskList,
                defaultTab,
                userType,
                onChange,
                pathway,
            }}>
            <WorkspaceView showHeader={showHeader} />
        </WorkspaceContext.Provider>
    )
};
