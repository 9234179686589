import { Text, rem } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { JumboHeaderProps } from '../type';


export const JumboHeader : RFC<JumboHeaderProps> = ({
    text,
}) =>
    <Text
        size={rem(34)}
        fw={700}>
        {text}
    </Text>;
