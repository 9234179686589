import { Props, TabConfigType } from './type';
import { Tabs, Text, rem } from '@mantine/core';
import { useId } from '@mantine/hooks';
import { ReactElement } from 'react';
import { RFC } from '@app/ui/type';


const renderTabsList = (
    tabsConfig : TabConfigType[],
    id         : string,
) : ReactElement =>
    <Tabs.List>
        {tabsConfig.map(tab =>
            <Tabs.Tab
                key={`${id}-${tab.value}`}
                value={tab.value}>
                <Text fz={rem(24)}>
                    {tab.label}
                </Text>
            </Tabs.Tab>,
        )}
    </Tabs.List>;

const renderTabsPanel = (
    tabsConfig : TabConfigType[],
    id : string,
) : ReactElement =>
    <>
        {tabsConfig.map(tab =>
            <Tabs.Panel
                key={`${id}-${tab.value}`}
                value={tab.value}
                mt='xl'>
                {tab.content}
            </Tabs.Panel>,
        )}
    </>;

export const TabList : RFC<Props> = ({
    tabsConfig, defaultTab,
}) => {

    const uuid = useId();

    return (
        <Tabs
            variant='outline'
            defaultValue={defaultTab}>
            {renderTabsList(tabsConfig, uuid)}
            {renderTabsPanel(tabsConfig, uuid)}
        </Tabs>
    );
};
