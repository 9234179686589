import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { Group, Text } from '@mantine/core';
import { ToggleItemProps } from './type';
import { RFC } from '@app/ui/type';

// Add all icons to the library so you can use it in your page
library.add(fas);

export const ToggleItem : RFC<ToggleItemProps> = ({
    text, icon,
}) =>
    <Group wrap='nowrap'>
        <FontAwesomeIcon
            icon={icon}
            size='lg' />
        <Text fz={16}>
            {text}
        </Text>
    </Group>
