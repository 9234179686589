import { CoreModel } from '@app/type/framework/core/CoreModel';
import { EditFieldType } from '@app/ui/form/ClickToEdit/type';
import { CA } from '@app/type/framework/core/CoreAction';
import { Breadcrumb } from '@app/ui/static/Breadcrumb';
import { ClickToEdit } from '@app/ui/form/ClickToEdit';
import { Box, Group, Text, rem } from '@mantine/core';
import { useDispatch } from '@app/client/hook';
import { Action } from '@app/client/action';
import { App } from '@app/ui/AppContext';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const ModelViewHeader : RFC<Props<CoreModel>> = ({
    currentBreadCrumbs, title,
    icon, children, model,
}) => {

    console.log('Incoming <ModelViewHeader title>', title);

    const { isPhone } = useContext(App);

    const dispatch = useDispatch();

    const handleChangeText = (
        value : string, name : string,
    ) : CA => {

        console.log('Incoming changeText title>', title);

        if(!model)
            return dispatch(Action.noOp());

        console.log(`Calling update and setting> (${title}) to (${value})`);

        return dispatch(Action.updateStashModelSync({
            model : {
                ...model,
                [name] : value,
            },
        }));
    };

    // debugger;

    return (
        <>
            {currentBreadCrumbs &&
                <Box style={{ paddingLeft : '76px'}}>
                    <Breadcrumb value={currentBreadCrumbs} />
                </Box>
            }
            <Group
                wrap='nowrap'
                pl='xl'
                pr='xl'>
                <Box
                    w={rem(30)}
                    m='sm'>
                    {icon}
                </Box>
                <ClickToEdit
                    size='xl'
                    type={EditFieldType.TextBox}
                    onChange={handleChangeText}
                    model={model}
                    value={title}
                    name='title'>
                    <Text
                        size={rem(isPhone ? 20 : 44)}
                        variant='gradient'
                        fw={300}
                        pl='sm'
                        pt='md'
                        pb='md'
                        gradient={{
                            from : 'blue',
                            to   : 'cyan',
                            deg  : 90,
                        }}>
                        {title}
                    </Text>
                </ClickToEdit>
                {children}
            </Group>
        </>
    );
}
