import { IconCategory, IconFilter, IconSortAscending, IconSortDescending } from '@tabler/icons-react';
import { PathwayStatusType } from '@app/constant/enum/model/PathwayStatusTypeEnum';
import { OrderField, SortField, SortFilterProps } from './type';
import { Group, Select, rem } from '@mantine/core';
import React, { useContext } from 'react';
import { App } from '@app/ui/AppContext';
import { RFC } from '@app/ui/type';


export const SortPathwayHeader : RFC<SortFilterProps> = ({
    config, onChange,
}) => {

    const { isTablet, isPhone } = useContext(App);

    const handleChangeFilterBy = (filterBy : string | null) : unknown =>
        filterBy && onChange({
            ...config,
            filter : filterBy as PathwayStatusType,
        });

    const handleChangeSortBy = (sortBy : string | null) : unknown =>
        sortBy && onChange({
            ...config,
            sortBy : sortBy as SortField,
        });

    const handleChangeOrderBy = (orderBy : string | null) : unknown =>
        orderBy && onChange({
            ...config,
            orderBy : orderBy as OrderField,
        });

    const isSmall = isPhone || isTablet;

    const variant = 'unstyled';
    const width   = isSmall ? 130  : 170;
    const size    = 'xs';
    PathwayStatusType

    return (
        <Group justify='center'>
            {config.filter &&
                <Select
                    onChange={handleChangeFilterBy}
                    leftSection={<IconCategory />}
                    checkIconPosition='right'
                    value={config.filter}
                    variant={variant}
                    label='Status'
                    w={rem(width)}
                    size={size}
                    c='dimmed'
                    data={[{
                        value : PathwayStatusType.Published,
                        label : PathwayStatusType.Published,
                    }, {
                        value : PathwayStatusType.Completed,
                        label : PathwayStatusType.Completed,
                    }, {
                        value : PathwayStatusType.Enrolled,
                        label : PathwayStatusType.Enrolled,
                    }, {
                        value : PathwayStatusType.Draft,
                        label : PathwayStatusType.Draft,
                    }, {
                        value : PathwayStatusType.Pending,
                        label : PathwayStatusType.Pending,

                    }]} />
            }
            <Select
                onChange={handleChangeSortBy}
                leftSection={<IconFilter />}
                value={config.sortBy}
                variant={variant}
                w={rem(width)}
                label='Sort'
                size={size}
                c='dimmed'
                data={[{
                    value : SortField.UpdatedAt,
                    label : 'Updated',
                }, {
                    value : SortField.CreatedAt,
                    label : 'Created',
                }]} />
            <Select
                leftSection={
                    config.orderBy === OrderField.Ascending
                        ? <IconSortAscending />
                        : <IconSortDescending />
                }
                onChange={handleChangeOrderBy}
                value={config.orderBy}
                variant={variant}
                w={rem(width)}
                label='Order'
                size={size}
                c='dimmed'
                data={[{
                    value : OrderField.Descending,
                    label : 'High to Low',
                }, {
                    value : OrderField.Ascending,
                    label : 'Low to High',
                }]} />
        </Group>
    );
};
