import RayRun01 from '@app/asset/patent/ray-gun-01.png';
import RayRun02 from '@app/asset/patent/ray-gun-02.png';
import { Box, Grid, Paper, Text, rem } from '@mantine/core';
import { SectionCentered } from './SectionCentered';
import Image from 'next/image';
import { RFC } from '@app/ui/type';


export const SectionDrawings : RFC = () =>
    <Box
        p='xl'
        mb='xl'>
        <SectionCentered text='DRAWINGS' />
        <Grid>
            <Grid.Col span={6}>
                <Paper
                    m='lg'
                    shadow='md'
                    p='lg'>
                    <Image
                        sizes='100vw'
                        style={{
                            width  : '100%',
                            height : 'auto',
                        }}
                        width={1024}
                        height={1024}
                        src={RayRun01}
                        alt='Ray Gun 01' />
                    <Text fz={rem(22)}>
                        {'Figure 1'}
                    </Text>
                </Paper>
            </Grid.Col>
            <Grid.Col span={6}>
                <Paper
                    m='lg'
                    shadow='md'
                    p='lg'>
                    <Image
                        sizes='100vw'
                        style={{
                            width  : '100%',
                            height : 'auto',
                        }}
                        width={1024}
                        height={1024}
                        src={RayRun02}
                        alt='Ray Gun 02' />
                    <Text fz={rem(22)}>
                        {'Figure 2'}
                    </Text>
                </Paper>
            </Grid.Col>
        </Grid>
    </Box>;
