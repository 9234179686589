import rehypeHighlight from 'rehype-highlight';
import Markdown from 'react-markdown';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const MarkdownText : RFC<Props> = ({ children }) =>
    <Markdown rehypePlugins={[rehypeHighlight]}>
        {children}
    </Markdown>
