import { Table, Text, rem } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import React from 'react';


export const ModelMetadata : RFC<Props> = ({
    label, value,
}) =>
    <Table.Tr>
        <Table.Th>
            <Text
                c='#222'
                fz={rem(12)}
                ta='left'
                fw={400}>
                {label}
            </Text>
        </Table.Th>
        <Table.Th>
            <Text
                c='dimmed'
                ta='right'
                fz={rem(12)}>
                {value}
            </Text>
        </Table.Th>
    </Table.Tr>
