import { ModelBoardContextType } from './type';
import React from 'react';


export const ModelBoardContext = React.createContext<ModelBoardContextType>({

    modelList : [],

    renderCompose : (groupId : string) => {
        throw new Error(`ModelBoardContext.Provider not implemented ${groupId}`);
    },
    onAddItem : (groupId : string) => {
        throw new Error(`ModelBoardContext.Provider not implemented ${groupId}`);
    },
});
