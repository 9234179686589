import { RFC } from '@app/ui/type';
import { HeaderMetadataProps } from '../type';
import { Group, Text, rem} from '@mantine/core';


export const HeaderMetadata : RFC<HeaderMetadataProps> = ({
    label, value,
}) =>
    <Group
        wrap='nowrap'
        w='100%'
        justify='space-between'>
        <Group wrap='nowrap'>
            <Text
                fz={rem(26)}
                fw={700}>
                {label}
            </Text>
        </Group>
        <Group
            mr='xs'
            fz={rem(32)}
            fw={700}>

            {value}
        </Group>
    </Group>;
