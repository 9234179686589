import { ComplianceMetricGrid } from './ComplianceMetricGrid';
import { ComplianceReport } from './ComplianceReport';
import { rem, Grid, Box, Text } from '@mantine/core';
import { ComplianceGrade } from './ComplianceGrade';
import { RFC } from '@app/ui/type';


export const ComplianceContent : RFC = () =>
    <Box w='100%'>
        <Text
            ta='center'
            fw={100}
            gradient={{ deg : 133, from : 'blue', to : 'cyan' }}
            variant='gradient'
            fz={rem(48)}>
            {'Analysis Results'}
        </Text>
        <Grid>
            <Grid w='100%'>
                <Grid.Col span={5}>
                    <ComplianceGrade />
                </Grid.Col>
                <Grid.Col
                    span={7}>
                    <ComplianceMetricGrid />
                </Grid.Col>
            </Grid>

            <Grid.Col span={12}>
                <ComplianceReport />
            </Grid.Col>
        </Grid>
    </Box>;
