import { PatentClassificationSystem } from '../enum/model/PatentClassificationSystemEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { PatentClassificationModel } from '@app/type/model/PatentClassificationModel';


export const DefaultPatentClassification : PatentClassificationModel = {
    ...DefaultCoreModel,

    modelType : ModelType.PatentClassification,

    system   : PatentClassificationSystem.IPC,
    subclass : 'A',
    subgroup : '10',
    section  : 'A',
    class    : '10',
    group    : '10',
    version  : null,
};

export const MakePatentClassification = (
    overrides : Partial<PatentClassificationModel>,
) : PatentClassificationModel => ({
    ...DefaultPatentClassification,
    ...overrides,
});
