import { SegmentedControl, SegmentedControlItem  } from '@mantine/core';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { ToggleItem } from './ToggleItem';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const UserTypeToggle : RFC<Props> = ({
    value, onChange,
}) => {
    const makeSegmentItem = (
        value : string,
        icon  : IconProp,
    ) : SegmentedControlItem => ({
        value,
        label : <ToggleItem
            icon={icon}
            text={value}/>,
    });

    return (
        <SegmentedControl
            onChange={onChange as (value : string) => void}
            value={value}
            variant='outline'
            c='dimmed'
            size='xs'
            data={[
                makeSegmentItem('Businesses View', 'legal'),
                makeSegmentItem('Client\'s View',   'user' ),
            ]} />
    );
};
