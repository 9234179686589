import { Center, Paper, rem, Text } from '@mantine/core';
import { PathwayContext } from '../PathwayContext';
import { StartButton } from './StartPatent';
import React, { useContext } from 'react';
import { App } from '@app/ui/AppContext';
import { RFC } from '@app/ui/type';


export const FloatingBanner : RFC = () => {
    const { pathway } = useContext(PathwayContext);

    if(!useContext(App).isDesktop)
        return;

    return (
        <Paper
            withBorder={true}
            shadow='xl'
            w={rem(600)}
            p='xl'>
            <Text fz={rem(36)}>
                {pathway.title}
            </Text>
            <Text
                fz={rem(24)}
                c='dimmed'>
                {pathway.description}
            </Text>
            <Center>
                <StartButton />
            </Center>

        </Paper>
    );
};
