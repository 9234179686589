import { WorkspaceTab } from '@app/constant/enum/ui/WorkspaceTabEnum';
import { TabConfigType } from '@app/ui/control/TabList/type';
import { TabCompliance } from './Tabs/TabCompliance';
import { TabDocument } from './Tabs/TabDocument';
import { TabResearch } from './Tabs/TabResearch';
import { IconPhoto } from '@tabler/icons-react';
import { TabClaims } from './Tabs/TabClaims';
import { TabAsset } from './Tabs/TabAsset';
import { PathwayModel } from '@app/type/model/PathwayModel';
import { TabTask } from './Tabs/TabTask';


export const getTabsConfig = (
    pathway : PathwayModel,
) : TabConfigType[] => {

    const makeTabConfig = (
        tab       : string,
        label     : string,
        urlSuffix : string,
        content   : React.ReactNode) : TabConfigType => {

        return {
            label,
            textSize : 'xl',
            value    : tab,
            icon     : <IconPhoto />,
            url      : `/pathway/${pathway.hash}/${urlSuffix}`,
            content,
        };
    };

    return [
        makeTabConfig(WorkspaceTab.Tasks,      'Tasks',      WorkspaceTab.Tasks,      <TabTask selectedTaskHash={null} />),
        makeTabConfig(WorkspaceTab.Research,   'Research',   WorkspaceTab.Research,   <TabResearch />),
        makeTabConfig(WorkspaceTab.Assets,     'Documents',  WorkspaceTab.Assets,     <TabAsset selectedAssetHash={null} />),
        makeTabConfig(WorkspaceTab.Notes,      'Notes',      WorkspaceTab.Notes,      <TabDocument selectedAssetHash={null}  />),
        makeTabConfig(WorkspaceTab.Claims,     'Patent',     WorkspaceTab.Claims,     <TabClaims />),
        makeTabConfig(WorkspaceTab.Compliance, 'Compliance', WorkspaceTab.Compliance, <TabCompliance />),
    ];
};
