import { Button, CloseButton, VisuallyHidden, Text } from '@mantine/core';
import { CloseDocumentButtonProps } from '../type';
import { RFC } from '@app/ui/type';
import React from 'react';


export const CloseDocumentButton : RFC<CloseDocumentButtonProps> = ({
    onClose,
}) =>
    <Button
        size='sm'
        onClick={onClose}
        aria-label='Close Document'
        variant='subtle'
        rightSection={
            <CloseButton
                aria-label='Close Document'
                variant='transparent'
                size='md'>
                <VisuallyHidden>
                    {'Close Document'}
                </VisuallyHidden>
            </CloseButton>
        }>
        <Text size='md'>
            {'Close'}
        </Text>
    </Button>
