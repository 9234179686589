import { DefaultPathway } from '@app/constant/default/DefaultPathway';
import { UserType } from '@app/constant/enum/model/UserTypeEnum';
import { WorkspaceContextType } from './type';
import { PathwayModel } from '@app/type/model/PathwayModel';
import React from 'react';


export const WorkspaceContext = React.createContext<WorkspaceContextType>({
    selectedMilestoneHash : null,
    pathwayMilestoneList  : [],
    pathwayTaskList       : [],
    selectedChatHash      : null,
    defaultTab            : 'Tasks123fixme',
    onChange              : (_pathway : PathwayModel) => {},
    userType              : UserType.Generic,
    pathway               : DefaultPathway,
});
