export enum PatentClaimType {
    MultipleDependent = 'MultipleDependent',
    MeansPlusFunction = 'MeansPlusFunction',
    Composition       = 'Composition',
    Independent       = 'Independent',
    Beauregard        = 'Beauregard',
    Apparatus         = 'Apparatus',
    Dependent         = 'Dependent',
    Software          = 'Software',
    Product           = 'Product',
    Process           = 'Process',
    Machine           = 'Machine',
    Omnibus           = 'Omnibus',
    System            = 'System',
    Method            = 'Method',
    Jepson            = 'Jepson',
    Use               = 'Use',
}
