
import { MockPatentModel } from '@app/constant/framework/MockPatentModel';
import { PatentContextType } from './type';
import React from 'react';


export const PatentContext = React.createContext<PatentContextType>({
    onGeneratePatent : () => {},
    patent           : MockPatentModel,
});
