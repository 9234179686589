import { Box, Collapse, List, Table, rem, Text, Paper } from '@mantine/core';
import { TextEditor } from '@app/ui/control/TextEditor';
import { SectionParagraph } from './SectionParagraph';
import { useDisclosure } from '@mantine/hooks';
import { SectionClaimProps } from '../type';
import { RFC } from '@app/ui/type';


export const SectionClaim : RFC<SectionClaimProps> = ({
    claim,
}) => {
    const [opened, { toggle }] = useDisclosure(false);

    return (
        <Box
            onClick={toggle}
            fz={rem(20)}>
            <SectionParagraph
                referenceId={34}
                text={claim.claim} />
            <List
                ml='lg'>
                {claim.limitationList.map(limitation => (
                    <List.Item key={limitation.title}>
                        <Text
                            component='span'
                            fz={rem(20)}
                            fw={600}>
                            {limitation.title}
                        </Text>
                        {limitation.description &&
                            <List.Item>
                                <Text
                                    fz={rem(20)}
                                    component='span'>
                                    {limitation.description}
                                </Text>
                            </List.Item>
                        }
                    </List.Item>
                ))}
            </List>
            <Collapse in={opened}>
                <Paper
                    style={{
                        backgroundColor : '#e1e0eb',
                    }}
                    shadow='xl'
                    p='sm'>
                    <Text
                        fw={200}
                        fz={rem(24)}>
                        {'Claim Editor'}
                    </Text>
                    <TextEditor
                        content={claim.claim}
                        minHeight={0} />
                    <Text
                        fz={rem(20)}
                        fw={200}>
                        {'Claim Details'}
                    </Text>
                    <Table
                        fz={rem(20)}
                        ml='lg'
                        mr='lg'>
                        <Table.Tbody>
                            <Table.Tr>
                                <Table.Td fw={600}>{'Preamble'}</Table.Td>
                                <Table.Td>{claim.preamble}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td fw={600}>{'Transitional Phrase'}</Table.Td>
                                <Table.Td>{claim.transitionalPhrase}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td fw={600}>
                                    {'Claim Type'}
                                </Table.Td>
                                <Table.Td>
                                    {claim.claimType}
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td fw={600}>
                                    {'Limitations'}
                                </Table.Td>
                                <Table.Td>
                                    <Table>
                                        <Table.Tbody>
                                            {claim.limitationList.map((limitation, index) => (
                                                <Table.Tr key={index}>
                                                    <Table.Td>
                                                        {limitation.title}
                                                    </Table.Td>
                                                </Table.Tr>
                                            ))}
                                        </Table.Tbody>
                                    </Table>
                                </Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td fw={600}>{'Amendment Details'}</Table.Td>
                                <Table.Td>{claim.amendmentDetails || 'None'}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td  fw={600}>{'Referenced Claims:'}</Table.Td>
                                <Table.Td>{claim.referencedClaims.length > 0 ? claim.referencedClaims.join(', ') : 'None'}</Table.Td>
                            </Table.Tr>
                            <Table.Tr>
                                <Table.Td  fw={600}>{'Has Been Amended:'}</Table.Td>
                                <Table.Td>{claim.hasBeenAmended ? 'Yes' : 'No'}</Table.Td>
                            </Table.Tr>
                        </Table.Tbody>
                    </Table>
                </Paper>

            </Collapse>
        </Box>
    );
};
