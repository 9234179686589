import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { selectLookupTask } from '@app/client/selector/ListSelector';
import { WorkspaceTaskList } from '../WorkspaceTaskList';
import { WorkspaceContext } from '../WorkspaceContext';
import { useSelector } from '@app/client/hook';
import { type TabTaskProps } from '../type';
import { Task } from '@app/ui/model/Task';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const TabTask : RFC<TabTaskProps> = ({
    selectedTaskHash,
}) => {
    const lookupTask = useSelector(selectLookupTask);

    const { pathway, pathwayMilestoneList } = useContext(WorkspaceContext);

    if (!selectedTaskHash)
        return (
            <WorkspaceTaskList />
        );

    const selectedTask = lookupTask[selectedTaskHash];

    return <Task
        pathwayMilestoneList={pathwayMilestoneList}
        mode={ControlModeType.Create}
        showRowContent={false}
        value={selectedTask}
        pathway={pathway} />;
};
