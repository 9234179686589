import { NumberedField } from './NumberedField';
import { SectionClaimListProps } from '../type';
import { RFC } from '@app/ui/type';
import { SectionClaim } from './SectionClaim';


export const SectionClaimList : RFC<SectionClaimListProps> = ({
    claimList,
}) => {
    return (
        <div>
            {claimList.map((claim, index) => (
                <NumberedField
                    key={index}
                    fieldNumber={claim.claimNumber}>
                    <SectionClaim claim={claim} />
                </NumberedField>
            ))}
        </div>
    );
};
