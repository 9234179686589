
export enum WorkspaceTab {
    Compliance = 'Compliance',
    Contacts   = 'Contacts',
    Calendar   = 'Calendar',
    Research   = 'Research',
    Settings   = 'Settings',
    Assets     = 'Assets',
    Claims     = 'Claims',
    Export     = 'Export',
    Tasks      = 'Tasks',
    Notes      = 'Notes',
}
