import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { PatentParagraphModel } from '@app/type/model/PatentParagraphModel';


export const DefaultPatentParagraph : PatentParagraphModel = {
    ...DefaultCoreModel,
    modelType   : ModelType.PatentParagraph,
    referenceId : 0,
    text        : '',
};


export const MakePatentParagraph = (
    overrides : Partial<PatentParagraphModel>,
) : PatentParagraphModel => ({
    ...DefaultPatentParagraph,
    ...overrides,
});

