export enum PathwatInspectorTab {
    Appearance = 'Appearance',
    Milestones = 'Milestones',
    Documents  = 'Documents',
    Contacts   = 'Contacts',
    Business   = 'Business',
    General    = 'General',
    Tasks      = 'Tasks',
    Help       = 'Help',
}
