import { SegmentedControl, Center, rem } from '@mantine/core';
import { IconBriefcase, IconUser } from '@tabler/icons-react';
import { Props } from './type';
import { RFC } from '@app/ui/type';


export const BusinessClientToggle : RFC<Props> = ({
    onChange, value,
}) =>
    <SegmentedControl
        onChange={onChange}
        fullWidth={true}
        value={value}
        radius='lg'
        mt='sm'
        mb='sm'
        data={[{
            value : 'Business',
            label : (
                <Center style={{ gap : 10 }}>
                    <IconBriefcase style={{ width : rem(16), height : rem(16) }} />
                    <span>
                        {'Business'}
                    </span>
                </Center>
            ),
        },{
            value : 'Client',
            label : (
                <Center style={{ gap : 10 }}>
                    <IconUser style={{ width : rem(16), height : rem(16) }} />
                    <span>
                        {'Client'}
                    </span>
                </Center>
            ),
        }]}/>
