import classes from './style.module.css';
import { SectionProps } from './type';
import { Box } from '@mantine/core';
import { RFC } from '@app/ui/type';
import React from 'react';


export const Section : RFC<SectionProps> = ({ children }) => {
    return (
        <Box
            p='md'
            m='md'
            className={classes.section}>
            {children}
        </Box>
    );
};
