import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { RelationshipModel } from '@app/type/model/RelationshipModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { ScreenSize } from '@app/constant/framework/ScreenSize';
import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { DocumentModel } from '@app/type/model/DocumentModel';
import { findMostRecentModel } from '@app/client/ClientUtil';
import { PathwayBuilder } from './PathwayBuilder';
import { PathwayContext } from './PathwayContext';
import { PathwayCreate } from './PathwayCreate';
import { useSelector } from '@app/client/hook';
import { PathwayPublished } from './Published';
import { UtilityUI } from '@app/ui/UtilityUI';
import { PathwayEnrolled } from './Enrolled';
import { PathwayRow } from './PathwayRow';
import { RFC } from '@app/ui/type';
import { ReactNode } from 'react';
import { Props } from './type';
import {
    selectLookupRelationship, selectRelationshipList, selectLookupMilestone,
    selectLookupDocument,     selectLookupAsset,      selectLookupChat,
    selectLookupTask,
} from '@app/client/selector/ListSelector';


export const Pathway : RFC<Props> = ({
    responsiveScreenType = ScreenSize.Desktop,
    selectedMilestoneHash,
    showManageControls = false,
    selectedAssetHash = null,
    value : pathway,
    isResearching,
    showRowContent,
    userType,
    onChange,
    mode,
}) => {

    const lookupRelationship = useSelector(selectLookupRelationship);
    const relationshipList   = useSelector(selectRelationshipList  );
    const lookupMilestone    = useSelector(selectLookupMilestone   );
    const lookupDocument     = useSelector(selectLookupDocument    );
    const lookupAsset        = useSelector(selectLookupAsset       );
    const lookupTask         = useSelector(selectLookupTask        );
    const lookupChat         = useSelector(selectLookupChat        );

    if(!pathway)
        return <LoadingSpinner label='Loading Pathway...' />;

    const renderPathway = () : ReactNode => {
        switch(mode) {
            case ControlModeType.Edit:
                return <PathwayBuilder
                    selectedMilestoneHash={selectedMilestoneHash}
                    responsiveScreenType={responsiveScreenType}
                    userType={userType}  />;

            case ControlModeType.Published : return <PathwayPublished />;
            case ControlModeType.Enrolled  : return <PathwayEnrolled />;
            case ControlModeType.Create    : return <PathwayCreate />;
            case ControlModeType.Row       : return <PathwayRow showRowContent={showRowContent} />;
        }
    }

    //  move to a selector or memoize?
    const pathwayMilestoneList = UtilityUI.getPathwayMilestoneList(
        pathway, lookupMilestone, relationshipList);

    const pathwayTaskList = UtilityUI.getPathwayTaskList(
        pathway, lookupTask, relationshipList);

    const chatList = UtilityUI.getPathwayChatList(
        pathway, lookupChat, relationshipList,
    ).sort((a, b) => b.chatOrder - a.chatOrder);

    const assetList = UtilityUI.getPathwayAssetList(
        pathway, lookupAsset, relationshipList)
        .sort((a, b) =>
            new Date(b.createdAt as string).getTime()
            - new Date(a.createdAt as string).getTime());

    let document : DocumentModel | null = null;

    if(selectedAssetHash) {
        const attachedDocumentRelationshipList = Object.values(lookupRelationship)
            .filter(relationship => {
                return relationship.sourceModelType  === ModelType.Document
                    && relationship.relationshipType === RT.AttachedTo
                    && relationship.destModelType    === ModelType.Asset
                    && relationship.destModelHash    === selectedAssetHash
            });

        const model = findMostRecentModel<RelationshipModel>(attachedDocumentRelationshipList);

        if(!model) return <LoadingSpinner />

        document = lookupDocument[model.sourceModelId!];
    }

    return (
        <PathwayContext.Provider
            value={{
                pathwayMilestoneList, showManageControls,
                selectedAssetHash,    pathwayTaskList,
                isResearching,        assetList,
                document,             chatList,
                onChange,             pathway,
            }}>
            {renderPathway()}
        </PathwayContext.Provider>
    )
};
