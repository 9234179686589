import { RFC } from '@app/ui/type';
import { GuideMilestoneProps } from './type';
import { Text, Paper } from '@mantine/core';
import React from 'react';


export const GuideMilestone : RFC<GuideMilestoneProps> = ({
    milestone, children,
}) =>
    <Paper
        radius='md'
        mb='md'
        p='xl'>
        <Text
            fz={32}
            fw={400}>
            {milestone.title}
        </Text>
        <Text fz={20}>
            {milestone.description}
        </Text>
        {children}
    </Paper>
