import { PathwayContext } from '@app/ui/model/Pathway/PathwayContext';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { MilestoneList } from '@app/ui/list/MilestoneList';
import { RelationshipConfig } from '@app/type/model/RelationshipConfig';
import React, { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const PathwaySettingsMilestone : RFC = () => {

    const { pathway, pathwayMilestoneList } = useContext(PathwayContext);

    const relationshipConfig : RelationshipConfig = {
        [RT.BelongsTo ] : pathway,
    };

    const handleReorder = (
        sourceIndex : number,
        destinationIndex : number | undefined,
    ) : void => {
        console.log('reorder', sourceIndex, destinationIndex);
    };

    return (
        <MilestoneList
            relationshipConfig={relationshipConfig}
            milestoneList={pathwayMilestoneList}
            onReorder={handleReorder} />
    );
};
