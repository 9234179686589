import { PathwayStatusType } from '@app/constant/enum/model/PathwayStatusTypeEnum';
import { PathwayModel } from '@app/type/model/PathwayModel';
import { RelationshipConfig } from '@app/type/model/RelationshipConfig';


export enum SortField {
    CreatedAt = 'CreatedAt',
    UpdatedAt = 'UpdatedAt',
}

export enum OrderField {
    Ascending  = 'Ascending',
    Descending = 'Descending',
}

export interface SortFilterConfig {
    orderBy  : OrderField;
    sortBy   : SortField;
    filter  ?: PathwayStatusType | null;
}
export interface SortFilterProps {
    onChange : (config : SortFilterConfig) => void;
    config   : SortFilterConfig;
}

export interface Props {
    relationshipConfig ?: RelationshipConfig;
    showRowContent      : boolean;
    showSortFilter     ?: boolean;
    pathwayList         : PathwayModel[];
    title              ?: string;
}
