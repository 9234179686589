import { Tabs, Title } from '@mantine/core';
import { TabPanelProps } from './type';
import { RFC } from '@app/ui/type';


export const ConfigPanel : RFC<TabPanelProps> = ({
    label, children, id,
}) =>
    <Tabs.Panel
        value={id}
        p='md'>
        {label &&
            <Title order={3}>
                {label}
            </Title>
        }
        {children}
    </Tabs.Panel>
