import { CoreFile, FriendlyFileInfo } from '@app/type/framework/core/CoreFile';
import { UnknownFileType } from '@app/constant/labels';


export const toFriendlyFileSize = (coreFile : CoreFile) : string => {

    const size = coreFile.size;

    const i = Math.floor( Math.log(size) / Math.log(1024) );
    const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];

    return parseFloat((size / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
};

export const toFriendlyFileType = (coreFile : CoreFile) : string => {
    if (!coreFile.type)
        return UnknownFileType;

    const type = coreFile.type;

    const friendlyTypeParts = type.split('/');

    const friendlyType = friendlyTypeParts[friendlyTypeParts.length - 1];

    if(!friendlyType)
        return UnknownFileType;

    return friendlyType.toUpperCase();
};

export const toFriendlyFileName = (coreFile : CoreFile) : string => {
    const path = coreFile.path;

    return path.split('/').pop() || coreFile.path; // Returns only the file name with extension.
};

export const toFriendlyFileDate = (coreFile : CoreFile) : string => {
    const date = coreFile.date;

    const dateObject = new Date(date);

    return `${dateObject.toLocaleDateString()} at ${dateObject.toLocaleTimeString()}` ;
};

export const toFriendlyFileInfo = (coreFile : CoreFile) : FriendlyFileInfo => ({
    size : toFriendlyFileSize(coreFile),
    type : toFriendlyFileType(coreFile),
    name : toFriendlyFileName(coreFile),
    date : toFriendlyFileDate(coreFile),
})

export const secondsToTimestamp = (seconds : number) : string => {

    const minutesFormatted = Math.floor(seconds % 3600 / 60).toString().padStart(2, '0');
    const secondsFormatted = Math.floor(seconds % 60).toString().padStart(2, '0');

    let hoursFormatted = Math.floor(seconds / 3600).toString().padStart(2, '0');

    if (hoursFormatted === '00')
        hoursFormatted = '';
    else
        hoursFormatted = `${hoursFormatted}:`;

    let runningOutOfVariableNames = `${hoursFormatted}${minutesFormatted}:${secondsFormatted}`;

    if (hoursFormatted.length !== 0) {

        if (runningOutOfVariableNames.startsWith('0'))
            runningOutOfVariableNames = runningOutOfVariableNames.substring(1);

    } else if (runningOutOfVariableNames.startsWith('00')) {

        runningOutOfVariableNames = runningOutOfVariableNames.substring(1);
    }

    return runningOutOfVariableNames;
};

export const Format = {
    secondsToTimestamp,
    toFriendlyFileInfo,
    toFriendlyFileSize,
    toFriendlyFileType,
    toFriendlyFileName,
    toFriendlyFileDate,
};
