import { DialogModalStateType } from '@app/constant/enum/ui/DialogModalStateTypeEnum';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { selectPagePathwayDetail } from '@app/client/selector/PageSelector';
import { DefaultDocument } from '@app/constant/default/DefaultDocument';
import { selectLookupAsset } from '@app/client/selector/ListSelector';
import { BrowseModelLayout } from '@app/ui/browse/BrowseModelLayout';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DefaultAsset } from '@app/constant/default/DefaultAsset';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { CoreHash } from '@app/type/framework/core/CoreHash';
import { useDispatch, useSelector } from '@app/client/hook';
import { FileUploader } from '@app/ui/control/FileUploader';
import { CA } from '@app/type/framework/core/CoreAction';
import { WorkspaceDocument } from './WorkspaceDocument';
import { AssetModel } from '@app/type/model/AssetModel';
import { ModelStash } from '@app/type/model/ModelStash';
import { NoContent } from '@app/ui/control/NoContent';
import { PathwayContext } from '../PathwayContext';
import { Box, Text, rem } from '@mantine/core';
import { Spacer } from '@app/ui/layout/Spacer';
import { Asset } from '@app/ui/model/Asset';
import { Action } from '@app/client/action';
import React, { useContext } from 'react';
import { ViewProps  } from './type';
import { RFC } from '@app/ui/type';


export const WorkspaceAssetList : RFC<ViewProps> = () => {

    const { dialogModalStateType } = useSelector(selectPagePathwayDetail);

    const { pathway, assetList, selectedAssetHash } = useContext(PathwayContext);

    const lookupAsset = useSelector(selectLookupAsset);

    const dispatch = useDispatch();

    const handlecreateMatterAsset = () : CA => dispatch(Action.noOp());

    const handleChangeCompose = (_newAsset : AssetModel) : CA => dispatch(Action.noOp());

    const onUploadComplete = (
        model      : CoreModel,
        modelStash : ModelStash,
    ) : void => {
        dispatch(Action.updateStashNoSync(modelStash));
    };

    const handleUploadChange = (_model : CoreModel) : void => {};

    const handleAssetClick = (assetHash : CoreHash) : void => {
        dispatch(Action.selectPathwayAsset(assetHash));
    };

    if (selectedAssetHash) {
        const selectedAsset = lookupAsset[selectedAssetHash];

        return <WorkspaceDocument asset={selectedAsset} />;
    }

    return (
        <BrowseModelLayout
            dialogModalStateType={dialogModalStateType}
            composingModel={DefaultAsset}
            onNewModal={handlecreateMatterAsset}
            headerRight={
                <FileUploader
                    onUploadComplete={onUploadComplete}
                    onChange={handleUploadChange}
                    value={pathway}>
                    <NoContent title='Upload Document - Drag and Drop or Click' />
                </FileUploader>
            }
            modelType={ModelType.Asset}
            modelList={assetList}
            noItemsContent={
                <Text
                    fz={rem(32)}
                    fs='italic'
                    c='dimmed'
                    fw={200}>
                    {'- No Documents -'}
                </Text>
            }
            title={
                dialogModalStateType === DialogModalStateType.Closed
                    ? 'Related Documents'
                    : 'Asset Sessions'
            }
            renderItem={item =>
                <Box onClick={() => handleAssetClick(item.hash)}>
                    <Asset
                        document={DefaultDocument}
                        mode={ControlModeType.Row}
                        value={item as AssetModel}
                        showManageControls={true}
                        relationshipList={[]} />
                    <Spacer showLine={false} />
                </Box>
            }
            renderCompose={
                <Asset
                    document={DefaultDocument}
                    onChange={handleChangeCompose}
                    value={DefaultAsset}
                    mode={ControlModeType.Create}
                    showManageControls={false}
                    relationshipList={[]} />
            } />
    );
};
