import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { PatentInventorModel } from '@app/type/model/PatentInventorModel';


export const DefaultPatentInventor : PatentInventorModel = {
    ...DefaultCoreModel,
    modelType : ModelType.PatentInventor,
    location  : '',
    name      : '',
};

export const MakePatentInventor = (
    overrides : Partial<PatentInventorModel>,
) : PatentInventorModel => ({
    ...DefaultPatentInventor,
    ...overrides,
});
