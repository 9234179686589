import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { DefaultPathway } from '@app/constant/default/DefaultPathway';
import { DefaultTask } from '@app/constant/default/DefaultTask';
import { LoadingSpinner } from '@app/ui/static/LoadingSpinner';
import { ModelBoard } from '@app/ui/control/ModelBoard';
import { WorkspaceContext } from './WorkspaceContext';
import { TaskModel } from '@app/type/model/TaskModel';
import { Container } from '@mantine/core';
import React, { useContext } from 'react';
import { Task } from '@app/ui/model/Task';
import { ViewProps  } from './type';
import { RFC } from '@app/ui/type';


export const WorkspaceTaskList : RFC<ViewProps> = () => {
    const {
        pathwayTaskList, pathway, onChange, pathwayMilestoneList,
    } = useContext(WorkspaceContext);

    if (!pathwayTaskList)
        return <LoadingSpinner label='Loading...' />;

    const handleAddItem = (groupId : string) : void => {
        console.log('groupId', groupId)
    }

    const handleChangeTask = (task : TaskModel) : void => {
        console.log(task);

        onChange && onChange(DefaultPathway);
    }

    return (
        <Container
            fluid={true}
            style={{
                marginBottom : '300px',
            }}>
            <ModelBoard
                modelList={pathwayTaskList}
                onAddItem={handleAddItem}
                renderCompose={(_groupId : string) =>
                    <Task
                        pathwayMilestoneList={pathwayMilestoneList}
                        mode={ControlModeType.Create}
                        value={DefaultTask}
                        onChange={handleChangeTask}
                        showRowContent={false}
                        pathway={pathway} />
                } />
        </Container>
    );
};
