import { RFC } from '@app/ui/type';

import { Text, rem } from '@mantine/core';
import { HeaderLatinProps } from '../type';


export const HeaderLatin : RFC<HeaderLatinProps> = ({
    text,
}) =>
    <Text
        ml={rem(52)}
        mt={-10}
        size={rem(28)}
        fw={600}>
        {text}
    </Text>;

