import { Orientation } from '@app/constant/framework/Orientation';
import { WorkspaceContext } from '../WorkspaceContext';
import { Notebook } from '@app/ui/control/Notebook';
import { type TabAssetProps } from '../type';
import { Container } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { useContext } from 'react';


export const TabDocument : RFC<TabAssetProps> = () => {

    const { pathway } = useContext(WorkspaceContext);

    return (
        <Container fluid={true}>
            <Notebook
                orientation={Orientation.Vertical}
                model={pathway} />
        </Container>
    )
};
