import { CoreItemRow } from '@app/ui/layout/CoreItemRow';
import { MilestoneMetadata } from './MilestoneMetadata';
import { RFC } from '@app/ui/type';
import { ViewProps } from './type';


export const MilestoneRow : RFC<ViewProps> = ({
    showManageControls : _showManageControls, milestone,
}) =>
    <CoreItemRow model={milestone}>
        <MilestoneMetadata
            showManageControls={false}
            milestone={milestone} />
    </CoreItemRow>
