import { ComplianceContext } from './ComplianceContext';
import { Box, Button, Text, rem } from '@mantine/core';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ComplianceGrade : RFC = () => {

    const { model : _model } = useContext(ComplianceContext);

    return (
        <Box ta='center'>
            <Text fz={rem(20)}>
                {'Good Chance of Acceptance'}
            </Text>
            <Text
                ta='center'
                gradient={{ deg : 133, from : 'blue', to : 'green' }}
                variant='gradient'
                lh={1.2}
                fz={rem(220)}
                c='blue'
                fw={300}>
                {'B+'}
            </Text>
            <Button
                aria-label='Re-Run Analysis'
                variant='subtle'
                size='md'>
                {'Re-Run Analysis'}
            </Button>
        </Box>
    );
};
