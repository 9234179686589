import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { PathwayContext } from '@app/ui/model/Pathway/PathwayContext';
import { WorkspaceTab } from '@app/constant/enum/ui/WorkspaceTabEnum';
import { ResponsiveTester } from '@app/ui/control/ResponsiveTester';
import { UserType } from '@app/constant/enum/model/UserTypeEnum';
import { ScreenSize } from '@app/constant/framework/ScreenSize';
import { ResponsiveHeader } from '../ResponsiveHeader';
import { PathwayBuilderMainProps } from '../../type';
import { useContext, useState } from 'react';
import { Workspace } from '../../Workspace';
import { Box, Stack } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const PathwayBuilderMain : RFC<PathwayBuilderMainProps> =({
    selectedMilestoneHash, responsiveScreenType,
}) => {
    const [screenType, setScreenType] = useState<ScreenSize>(ScreenSize.Desktop);
    const [userType,   setUserType  ] = useState<UserType  >(UserType.Business);

    const handleScreenTypeChange = (value : ScreenSize) : void => setScreenType(value);
    const handleUserTypeChange   = (value : UserType  ) : void => setUserType(  value);

    const { pathway, pathwayMilestoneList, pathwayTaskList } = useContext(PathwayContext);

    return (
        <Stack>
            <ResponsiveTester screenSize={responsiveScreenType}>
                <ResponsiveHeader
                    onScreenTypeChange={handleScreenTypeChange}
                    onUserTypeChange={handleUserTypeChange}
                    screenType={screenType}
                    userType={userType} />
                <Box
                    mr='md'
                    mt='md'
                    p='xs'>
                    <Workspace
                        selectedMilestoneHash={selectedMilestoneHash}
                        pathwayMilestoneList={pathwayMilestoneList}
                        pathwayTaskList={pathwayTaskList}
                        defaultTab={WorkspaceTab.Tasks}
                        mode={ControlModeType.View}
                        selectedChatHash={null}
                        userType={userType}
                        pathway={pathway} />
                </Box>
            </ResponsiveTester>
        </Stack>
    );
}
