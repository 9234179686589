import { Box, List, Text, ThemeIcon, rem } from '@mantine/core';
import { MilestoneModel } from '@app/type/model/MilestoneModel';
import { IconCircleCheck } from '@tabler/icons-react';
import React, { ReactNode, useContext } from 'react';
import { PathwayContext } from '../PathwayContext';
import { GuideMilestone } from './GuideMilestone';
import classes from './style.module.css';
import { GuideTask } from './GuideTask';
import { RFC } from '@app/ui/type';


export const Syllabus : RFC = () => {
    const {
        pathway, pathwayTaskList, pathwayMilestoneList,
    } = useContext(PathwayContext);

    const renderMilestoneTaskList = (milestone : MilestoneModel) : ReactNode =>
        <List
            icon={
                <ThemeIcon
                    color='teal'
                    size={64}
                    radius='xl'>
                    <IconCircleCheck
                        style={{
                            height : rem(64),
                            width  : rem(64),
                        }} />
                </ThemeIcon>
            }>
            {pathwayTaskList
                .filter(task => task.milestoneHash === milestone.hash)
                .sort((a, b) => a.order - b.order)
                .map(task =>
                    <List.Item key={task.hash}>
                        <GuideTask task={task} />
                    </List.Item>,
                )}
        </List>


    return (
        <Box className={classes.syllabus}>
            <Text
                fw={500}
                fz={rem(36)}>
                {`${pathway.title} Guide`}
            </Text>
            <Text
                fz={rem(24)}
                mb='lg'>
                {pathway.description}
            </Text>
            <List>
                {pathwayMilestoneList
                    .sort((a, b) => a.order - b.order)
                    .map(milestone =>
                        <List.Item key={milestone.hash}>
                            <GuideMilestone milestone={milestone}>
                                {renderMilestoneTaskList(milestone)}
                            </GuideMilestone>
                        </List.Item>,
                    )}
            </List>
        </Box>
    );
};
