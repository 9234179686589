import { Paper, Stack, Text, rem } from '@mantine/core';
import { ModelBoardContext } from './ModelBoardContext';
import { ItemGroupProps } from './type';
import { BoardItem } from './BoardItem';
import { TaskModel } from '@app/type/model/TaskModel';
import { AddItem } from './AddItem';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const BoardItemGroup : RFC<ItemGroupProps> = ({
    label, groupId, color,
}) => {

    const { modelList } = useContext(ModelBoardContext);

    const filteredList = modelList.filter(model =>
        model.status === groupId);

    return (
        <Stack p='sm'>
            <Text
                fz={rem(30)}
                c={color}
                fw={300}>
                {label}
            </Text>
            {filteredList.map(model =>
                <BoardItem
                    key={`board-${model.hash}`}
                    model={model as TaskModel} />,
            )}

            <Paper p='md'>
                <Stack align='center'>
                    <AddItem groupId={groupId} />
                </Stack>
            </Paper>

        </Stack>
    )
};
