import { Text, Paper } from '@mantine/core';
import { GuideTaskProps } from './type';
import { RFC } from '@app/ui/type';
import React from 'react';


export const GuideTask : RFC<GuideTaskProps> = ({ task }) =>
    <Paper
        withBorder={true}
        shadow='sm'
        mt='md'
        ml='md'
        mb='md'
        p='xl'>
        <Text fz={28}>
            {task.title}
        </Text>
        <Text fz={20}>
            {task.description}
        </Text>
    </Paper>
