import { SectionParagraph } from './SectionParagraph';
import { SectionCentered } from './SectionCentered';
import { Box, Button, Group } from '@mantine/core';
import { SectionListProps } from '../type';
import { FcAddRow } from 'react-icons/fc';
import { RFC } from '@app/ui/type';


export const SectionList : RFC<SectionListProps> = ({
    sectionList,
}) => sectionList.map(section =>
    <Box key={section.title}>
        <SectionCentered text={section.title} />
        {section.paragraphList.map(paragraph=> (
            <Box key={paragraph.text}>
                <SectionParagraph
                    referenceId={paragraph.referenceId}
                    text={paragraph.text}/>
                <Group justify='center'>
                    <Button
                        aria-label='Add'
                        variant='subtle'>
                        <Box style={{ opacity : 0.5}}>
                            <FcAddRow size={30} />
                        </Box>
                    </Button>
                </Group>
            </Box>
        ))}
    </Box>,
)
