import { RelationshipConfig } from '@app/type/model/RelationshipConfig';
import { CoreModel } from '@app/type/framework/core/CoreModel';


export interface Props {
    model : CoreModel;
}

export interface ComplianceContextType {
    relationshipConfig : RelationshipConfig;
    metricList         : Metric[];
    model              : CoreModel;
}

export interface MetricCriteriaListProps {
    criteriaList : Criteria[];
}

export interface MetricCriteriaProps {
    isLastItem : boolean;
    criteria   : Criteria;
}


export interface ComplianceMetricTileProps {
    metric : Metric;
}

export interface ReportMetricProps {
    metric : Metric;
}


export enum LetterGrade {
    APlus  = 'A+',
    A      = 'A',
    AMinus = 'A-',
    BPlus  = 'B+',
    B      = 'B',
    BMinus = 'B-',
    CPlus  = 'C+',
    C      = 'C',
    CMinus = 'C-',
    DPlus  = 'D+',
    D      = 'D',
    DMinus = 'D-',
    F      = 'F',
}

export interface Metric {
    simpleDescription : string;
    importanceScore   : number;
    dataComputed      : string;
    criteriaList      : Criteria[];
    description       : string;
    hash              : string;
    name              : string;
    grade             : LetterGrade;
}


export interface Criteria {
    analysis : string;
    query    : string;
    label    : string;
    grade    : LetterGrade;
    hash     : string;
}
