import { Compliance } from '@app/ui/control/Compliance';
import { PathwayContext } from '@app/ui/model/Pathway/PathwayContext';
import { Box } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { useContext } from 'react';


export const TabCompliance : RFC = () =>
    <Box>
        <Compliance model={useContext(PathwayContext).pathway} />
    </Box>
