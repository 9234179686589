import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { WorkspaceTab } from '@app/constant/enum/ui/WorkspaceTabEnum';
import { UserType } from '@app/constant/enum/model/UserTypeEnum';
import { PathwayContext } from '../PathwayContext';
import { Container } from '@mantine/core';
import React, { useContext } from 'react';
import { Workspace } from '../Workspace';
import { RFC } from '@app/ui/type';


export const PathwayEnrolled : RFC = () => {
    const {
        pathway, pathwayMilestoneList, pathwayTaskList,
    } = useContext(PathwayContext);

    return (
        <Container fluid={true}>
            <Workspace
                pathwayMilestoneList={pathwayMilestoneList}
                pathwayTaskList={pathwayTaskList}
                defaultTab={WorkspaceTab.Tasks}
                selectedMilestoneHash={null}
                mode={ControlModeType.View}
                userType={UserType.Client}
                selectedChatHash={null}
                pathway={pathway} />
        </Container>
    );
};
