import { ComplianceContext } from './ComplianceContext';
import { ReportMetric } from './ReportMetric';
import { SimpleGrid } from '@mantine/core';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ComplianceReport : RFC = () => {

    const { metricList } = useContext(ComplianceContext);

    return (
        <SimpleGrid
            cols={2}
            spacing='xl'>
            {metricList.map((metric, index) =>
                <ReportMetric
                    key={`${metric.name}-${index}`}
                    metric={metric} />,
            )}
        </SimpleGrid>
    )
};
