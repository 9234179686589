import { IconGitBranch, IconGitCommit, IconGitPullRequest, IconMessageDots } from '@tabler/icons-react';
import { Text, Timeline } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const PathwayTimeline : RFC = () => {
    return (
        <Timeline
            active={1}
            bulletSize={24}
            lineWidth={2}>
            <Timeline.Item
                bullet={<IconGitBranch size={12} />}
                title='Basic Research Tasks'>
                <Text
                    c='dimmed'
                    size='sm'>{'You&apos;ve created new branch '}<Text
                        variant='link'
                        component='span'
                        inherit={true}>{'fix-notifications'}</Text>{' from master'}</Text>
                <Text
                    size='xs'
                    mt={4}>{'2 hours ago'}</Text>
            </Timeline.Item>

            <Timeline.Item
                bullet={<IconGitCommit size={12} />}
                title='Patent Search'>
                <Text
                    c='dimmed'
                    size='sm'>{'You&apos;ve pushed 23 commits to'}<Text
                        variant='link'
                        component='span'
                        inherit={true}>{'fix-notifications branch'}</Text></Text>
                <Text
                    size='xs'
                    mt={4}>{'52 minutes ago'}</Text>
            </Timeline.Item>

            <Timeline.Item
                title='Market Research'
                bullet={<IconGitPullRequest size={12} />}
                lineVariant='dashed'>
                <Text
                    c='dimmed'
                    size='sm'>{'You&apos;ve submitted a pull request'}<Text
                        variant='link'
                        component='span'
                        inherit={true}>{'Fix incorrect notification message (#187)'}</Text></Text>
                <Text
                    size='xs'
                    mt={4}>{'34 minutes ago'}</Text>
            </Timeline.Item>

            <Timeline.Item
                title='Patent Review'
                bullet={<IconMessageDots size={12} />}>
                <Text
                    c='dimmed'
                    size='sm'><Text
                        variant='link'
                        component='span'
                        inherit={true}>{'Robert Gluesticker'}</Text>{' left a code review on your pull request'}</Text>
                <Text
                    size='xs'
                    mt={4}>{'12 minutes ago'}</Text>
            </Timeline.Item>
        </Timeline>
    );
}
