import { NumberedField } from './NumberedField';
import { SectionMiniMetaProps } from '../type';
import { LabelValue } from './LabelValue';
import { Text, rem } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const SectionMiniMeta : RFC<SectionMiniMetaProps> = ({
    label, value, fieldNumber,
}) =>
    <NumberedField fieldNumber={fieldNumber}>
        <LabelValue
            leftSection={
                <Text
                    fz={rem(18)}
                    mr='xs'>
                    {label}
                </Text>
            }
            rightSection={
                <Text
                    fz={rem(20)}
                    fw={600}>
                    {value}
                </Text>
            } />
    </NumberedField>;


