import { RFC } from '@app/ui/type';
import { Group, Text, rem } from '@mantine/core';


export const SectionFooter : RFC = () =>
    <Group
        mb='xl'
        w='100%'
        justify='center'>
        <Text
            pl='xs'
            fz={rem(20)}>
            {'* '}
        </Text>
        <Text
            pl='xs'
            fz={rem(20)}>
            {'* '}
        </Text>
        <Text
            pl='xs'
            fz={rem(20)}>
            {'* '}
        </Text>
        <Text
            pl='xs'
            fz={rem(20)}>
            {'* '}
        </Text>
        <Text
            pl='xs'
            fz={rem(20)}>
            {'* '}
        </Text>
    </Group>;
