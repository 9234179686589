import { PathwayStatusType } from '@app/constant/enum/model/PathwayStatusTypeEnum';
import { BreadCrumbItem } from '@app/type/control/BreadCrumbItem';
import { RouteHref } from '@app/constant/route/RouteHref';
import { UI } from '@app/constant/UI';


export const currentBreadCrumbs : BreadCrumbItem[] = [{
    title : 'Dashboard',
    href  : RouteHref.Dashboard,
}, {
    title : UI.PathwayBuilderTitle,
    href  : RouteHref.BrowseAccounts,
}];


export const ColorLookup : Record<PathwayStatusType, string> = {
    [PathwayStatusType.Completed] : 'teal',
    [PathwayStatusType.Published] : 'green',
    [PathwayStatusType.Pending  ] : 'cyan',
    [PathwayStatusType.Enrolled ] : 'blue',
    [PathwayStatusType.Draft    ] : 'gray',
};
