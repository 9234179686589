import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { PatentClaimDefinitionModel } from '@app/type/model/PatentClaimDefinitionModel';


export const DefaultPatentClaimDefinition : PatentClaimDefinitionModel = {
    ...DefaultCoreModel,
    modelType  : ModelType.PatentClaimDefinition,
    definition : '',
    term       : '',
};

export const MakePatentClaimDefinition = (
    overrides : Partial<PatentClaimDefinitionModel>,
) : PatentClaimDefinitionModel => ({
    ...DefaultPatentClaimDefinition,
    ...overrides,
});
