import { FormEditCreateModel } from '@app/ui/form/FormEditCreateModel';
import { RFC } from '@app/ui/type';
import { ViewProps } from './type';


export const MilestoneCreate : RFC<ViewProps> = ({
    milestone, onChange,
}) => {
    const { title, description } =  milestone;

    const handleChangeFieldText = (text : string, name ?: string) : void => {
        if (!name)
            throw new Error('Name and text are required');

        return onChange && onChange({
            ...milestone,
            [name] : text,
        });
    };

    return (
        <FormEditCreateModel
            onChange={handleChangeFieldText}
            description={description}
            label='Create a Milestone'
            title={title}
            value=''>
            {/* {'Additional Fields'} */}
        </FormEditCreateModel>
    );
};
