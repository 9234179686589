import { ContentContextMenu } from '@app/ui/control/ContentContextMenu';
import { DefaultPathway } from '@app/constant/default/DefaultPathway';
import { DefaultChat } from '@app/constant/default/DefaultChat';
import { SectionParagraphProps } from '../type';
import { Box, Text, rem } from '@mantine/core';
import { useHover } from '@mantine/hooks';
import { RFC } from '@app/ui/type';
import { useState } from 'react';


export const SectionParagraph : RFC<SectionParagraphProps> = ({
    text, referenceId,
}) => {
    const { hovered, ref } = useHover();

    const [ isContextMenuOpen, setIsContextMenuOpen]  = useState(false);

    const handleChangeContentMenu = (isOpen : boolean) : void =>
        setIsContextMenuOpen(isOpen);

    return (
        <Box
            style={{
                backgroundColor : isContextMenuOpen ? 'rgba(109, 206, 255, 0.10)' : 'transparent',
                borderRadius    : rem(10),
                boxShadow       : isContextMenuOpen ? '10px 10px 52px -4px rgba(0,0,0,0.10)' : 'none',
                outline         : isContextMenuOpen ? '1px solid #ccc' : 'none',
                position        : 'relative',
            }}
            ref={ref}>
            <ContentContextMenu
                belongsToModel={DefaultPathway}
                onChange={handleChangeContentMenu}
                model={DefaultPathway}
                chat={DefaultChat}
                researchControlId='abc'
                isVisible={hovered} />
            <Text
                component='span'
                fz={rem(20)}
                fw={600}>
                {`[${String(referenceId).padStart(4, '0')}] `}
            </Text>
            <Text
                ml='xs'
                component='span'
                fz={rem(20)}>
                {text}
            </Text>
        </Box>
    );
};
