import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { PatentSectionModel } from '@app/type/model/PatentSectionModel';


export const DefaultPatentSection : PatentSectionModel = {
    ...DefaultCoreModel,
    modelType     : ModelType.PatentSection,
    paragraphList : [],
    title         : '',
};

export const MakePatentSection = (
    overrides : Partial<PatentSectionModel>,
) : PatentSectionModel => ({
    ...DefaultPatentSection,
    ...overrides,
});
