/* eslint-disable max-len */

import { LetterGrade, Metric } from './type';
import { v4 as uuid } from 'uuid';


export const ComplianceMockData : Metric[] = [{
    name              : 'Novelty',
    hash              : uuid(),
    grade             : LetterGrade.BMinus,
    simpleDescription : 'Assesses if the robotic arm is a new invention compared to existing technology.',
    importanceScore   : 95,
    dataComputed      : new Date().toISOString(),
    description       : 'Evaluates the uniqueness and originality of the robotic arm against previously patented technologies.',
    criteriaList      : [{
        analysis : 'No directly comparable patents exist, suggesting a high degree of novelty.',
        query    : 'Identify existing patents similar to the articulated joints of the robotic arm.',
        label    : 'Novelty Check',
        grade    : LetterGrade.A,
        hash     : uuid(),
    }, {
        analysis : 'Previous patents do not cover the specific integration of technologies in this robotic arm.',
        query    : 'Search for patents that might overlap with the new technological integrations of the robotic arm.',
        label    : 'Overlap Analysis',
        grade    : LetterGrade.A,
        hash     : uuid(),
    }],
}, {
    name              : 'Non-obviousness',
    hash              : uuid(),
    grade             : LetterGrade.D,
    simpleDescription : 'Determines if the invention isn\'t obvious to experts in the field.',
    importanceScore   : 90,
    dataComputed      : new Date().toISOString(),
    description       : 'Analyzes whether the technological advancements in the robotic arm are not immediately apparent to industry experts.',
    criteriaList      : [{
        analysis : 'The combination of technologies represents a significant step beyond current standards.',
        query    : 'Evaluate if the combination of fluidic muscles and sensors represents a non-obvious improvement.',
        label    : 'Innovation Step',
        grade    : LetterGrade.APlus,
        hash     : uuid(),
    }, {
        analysis : 'Novel use of materials and sensors could be seen as inventive.',
        query    : 'Analyze if the use of advanced materials in sensors is non-obvious.',
        label    : 'Material Innovation',
        grade    : LetterGrade.AMinus,
        hash     : uuid(),
    }],
}, {
    name              : 'Utility',
    hash              : uuid(),
    grade             : LetterGrade.AMinus,
    simpleDescription : 'Verifies that the robotic arm functions as intended and is useful.',
    importanceScore   : 85,
    dataComputed      : new Date().toISOString(),
    description       : 'Confirms that the robotic arm can perform its intended functions effectively in industrial settings.',
    criteriaList      : [{
        analysis : 'The arm performs tasks with precision exceeding current industry capabilities.',
        query    : 'Assess the operational performance of the robotic arm in precise assembly tasks.',
        label    : 'Operational Efficacy',
        grade    : LetterGrade.F,
        hash     : uuid(),
    }],
}, {
    name              : 'Industrial Applicability',
    hash              : uuid(),
    grade             : LetterGrade.AMinus,
    simpleDescription : 'Checks if the robotic arm can be made and used within the industry.',
    importanceScore   : 82,
    dataComputed      : new Date().toISOString(),
    description       : 'Determines the feasibility of manufacturing and utilizing the robotic arm across various industrial sectors.',
    criteriaList      : [{
        analysis : 'The design supports scalable production and integration into existing systems.',
        query    : 'Evaluate the scalability of the robotic arm\'s production.',
        label    : 'Scalability Check',
        grade    : LetterGrade.BPlus,
        hash     : uuid(),
    }],
}, {
    name              : 'Patentable Subject Matter',
    hash              : uuid(),
    grade             : LetterGrade.APlus,
    simpleDescription : 'Ensures the invention is eligible for a patent under the law.',
    importanceScore   : 88,
    dataComputed      : new Date().toISOString(),
    description       : 'Examines whether the robotic arm meets the legal criteria for patentable subject matter.',
    criteriaList      : [{
        analysis : 'The device falls within the realms of patentable technology under current regulations.',
        query    : 'Verify the patent eligibility of the articulated joints and sensor technology used in the robotic arm.',
        label    : 'Patent Eligibility Review',
        grade    : LetterGrade.AMinus,
        hash     : uuid(),
    }],
}, {
    name              : 'Specification and Enablement',
    hash              : uuid(),
    grade             : LetterGrade.AMinus,
    simpleDescription : 'Confirms that the patent application thoroughly describes how to make and use the robotic arm.',
    importanceScore   : 90,
    dataComputed      : new Date().toISOString(),
    description       : 'Assesses the completeness and clarity of the patent application, ensuring it enables a person skilled in the art to replicate the robotic arm.',
    criteriaList      : [{
        analysis : 'The patent application provides comprehensive construction details, enabling replication.',
        query    : 'Assess the completeness of the technical descriptions in the patent documentation for the robotic arm.',
        label    : 'Description Completeness',
        grade    : LetterGrade.A,
        hash     : uuid(),
    }, {
        analysis : 'Clear instructions and specifications are provided for each component of the arm.',
        query    : 'Review the clarity of instructions for assembling the robotic arm.',
        label    : 'Instruction Clarity',
        grade    : LetterGrade.APlus,
        hash     : uuid(),
    }],
}, {
    name              : 'Clear and Supported Claims',
    hash              : uuid(),
    grade             : LetterGrade.AMinus,
    simpleDescription : 'Evaluates if the patent claims are well-defined and supported by the description.',
    importanceScore   : 93,
    dataComputed      : new Date().toISOString(),
    description       : 'Analyzes whether the claims within the patent are adequately detailed and substantiated by the descriptive portion of the application.',
    criteriaList      : [{
        analysis : 'All claims are precise, providing clear boundaries for the protection sought.',
        query    : 'Verify the precision and support of claims related to technological advances in the robotic arm.',
        label    : 'Claim Precision',
        grade    : LetterGrade.CMinus,
        hash     : uuid(),
    }, {
        analysis : 'Claims are fully supported by experimental results detailed in the application.',
        query    : 'Examine the support for claims through documented experimental results within the patent.',
        label    : 'Experimental Support',
        grade    : LetterGrade.A,
        hash     : uuid(),
    }],
}, {
    name              : 'Prior Art Search',
    hash              : uuid(),
    grade             : LetterGrade.AMinus,
    simpleDescription : 'Confirms the invention is unique by comparing it against existing technology and patents.',
    importanceScore   : 89,
    dataComputed      : new Date().toISOString(),
    description       : 'Performs a thorough investigation of prior patents and publications to ensure the robotic arm\'s features are novel and non-obvious.',
    criteriaList      : [{
        analysis : 'No existing patents conflict with the new features of the robotic arm, ensuring its novelty.',
        query    : 'Conduct a detailed search for any prior patents overlapping with the new features of the robotic arm.',
        label    : 'Novelty Confirmation',
        grade    : LetterGrade.A,
        hash     : uuid(),
    }],
}];


export const InventionContext : string = `
# Sample Patent Application for a Robotic Arm

---

**Title of Invention:**

- Advanced Articulated Robotic Arm for Precision Assembly Tasks

**Applicants:**

- Jane Doe, Anytown, USA
- John Smith, Anytown, USA

**Inventors:**

- Jane Doe
- John Smith

**Assignee (if applicable):**

- RoboTech Innovations Inc., Anytown, USA

**U.S. Patent Classification:**

- Primary Class: 901 - Robotics
- Subclass: 123 - Robotic Arms, Programmable

**Related U.S. Patent Documents:**

- Provisional Application No.: 62/123456, filed January 15, 2022

**Foreign Application Priority Data (if applicable):**

- None

**References Cited [Referenced By]:**

- U.S. Patent Documents:
  - U.S. Pat. No. 8,123,456 to Doe et al. (2018)
  - U.S. Pat. No. 9,234,567 to Smith (2020)
- Foreign Patent Documents:
  - EP 1234567 (2019)
- Other Publications:
  - Smith, J. "Advancements in Robotic Manipulators," Journal of Robotics, 2021.

**Field of the Invention:**

- The present invention relates generally to the field of robotics, and more specifically, to an articulated robotic arm designed for high-precision tasks in various industrial applications.

**Background of the Invention:**

- **Industry Need:** The need for increased precision and flexibility in industrial robotics has grown as manufacturing tasks have become more complex and varied.
- **Prior Art Problems:** Existing robotic arms do not adequately address the need for high precision combined with high-speed operation and flexibility in task programming.
- **Objective:** The objective of this invention is to provide a robotic arm that offers improved precision, adaptability, and speed, suitable for a wide range of industrial applications.

**Summary of the Invention:**

- The invention provides a robotic arm that includes multiple articulated segments controlled by a sophisticated algorithm that optimizes movement accuracy and speed. The robotic arm features an innovative joint design using fluidic muscle actuators combined with sensor feedback for enhanced control.

**Brief Description of the Drawings:**

1. **Fig. 1** - Overall view of the robotic arm
2. **Fig. 2** - Detailed view of the joint mechanism
3. **Fig. 3** - Control system schematic
4. **Fig. 4** - Example of the arm performing a task

**Detailed Description of the Invention:**

- **Structure Description:**
  - The robotic arm comprises three main segments connected by two fluidic muscle-driven joints. Each joint includes a combination of sensors that detect position, force, and velocity, providing feedback to the control system.
- **Method of Operation:**
  - The method involves an initial calibration followed by dynamic adjustments during operation based on sensor input. The arm's software uses a real-time analysis to predict and counteract potential errors in movement, thereby enhancing precision.
- **Example of Use:**
  - An example use case involves the robotic arm assembling delicate electronic components where precision and gentleness are critical.

**Claims:**

1. A robotic arm comprising: a plurality of articulated segments; fluidic muscle actuators in each joint; sensors for position, force, and velocity; and a control system using said data to optimize movement accuracy.
2. The robotic arm of claim 1, wherein the control system further comprises real-time predictive analytics for movement adjustments.
3. A method of controlling a robotic arm, comprising: calibrating the arm; receiving sensor feedback; and adjusting arm movement based on predictive analytics.

**Abstract:**

- A robotic arm designed for high-precision tasks, featuring fluidic muscles for movement, advanced sensor integration for real-time feedback, and a sophisticated control system that enhances operational accuracy.

**Other Descriptive Sections:**

- **Description of Alternative Embodiments:**
  - Variations in the number of joints and segments to accommodate different industrial needs.
- **Operational Advantages:**
  - The robotic arm significantly reduces error rates in precision tasks and increases operational speed.

`.trim();


/* eslint-enable max-len */
