import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { PanelPlaceholder } from '@app/ui/layout/PanelPlaceholder';
import { MilestoneCreate } from './MilestoneCreate';
import { MilestoneView } from './MilestoneView';
import { MilestoneRow } from './MilestoneRow';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const Milestone : RFC<Props> = ({
    value : milestone,
    mode, onChange, showManageControls,
}) => {

    if (!milestone)
        return <PanelPlaceholder label='No Milestone Info' />;

    switch(mode) {
        case ControlModeType.View:
            return <MilestoneView
                showManageControls={showManageControls}
                milestone={milestone} />;

        case ControlModeType.Row:
            return <MilestoneRow
                showManageControls={showManageControls}
                milestone={milestone} />;

        case ControlModeType.Create:
            return <MilestoneCreate
                showManageControls={showManageControls}
                milestone={milestone}
                onChange={onChange} />;
        default:
            return <PanelPlaceholder label={`Milestone ControlModeType ${mode}`} />;
    }
};
