import { ModelMetadata } from '@app/ui/control/ModelMetadata';
import { UtilityUI } from '@app/ui/UtilityUI';
import { Table } from '@mantine/core';
import { ViewProps } from './type';
import { RFC } from '@app/ui/type';


export const MilestoneMetadata : RFC<ViewProps> = ({
    milestone,
}) =>
    <Table w='100%' >
        <Table.Tbody>
            <ModelMetadata
                label='Order'
                value={`${milestone.order}`} />
            <ModelMetadata
                label='Created'
                value={UtilityUI.toFormattedDate(milestone.createdAt)} />
            <ModelMetadata
                label='Updated'
                value={UtilityUI.toFormattedDate(milestone.updatedAt)} />
        </Table.Tbody>
    </Table>
