import { Box, Group, Stack, Text, rem } from '@mantine/core';
import { SectionIntClassificationProps } from '../type';
import { NumberedField } from './NumberedField';
import { RFC } from '@app/ui/type';


export const SectionIntClassification : RFC<SectionIntClassificationProps> = ({
    classificationList,
}) =>
    <NumberedField fieldNumber={null}>
        <Stack
            ml={rem(50)}
            gap={0}>
            {classificationList.map((classification, index) =>
                <Box key={`${index}-${classification.group}`}>
                    <Group
                        gap={0}
                        wrap='nowrap'
                        justify='space-between'>
                        <Text
                            w={rem(120)}
                            fz={rem(24)}
                            fw={800}
                            fs='italic'>
                            {classification.section}
                            {classification.class}
                            {classification.subclass}
                        </Text>
                        <Text
                            w={rem(120)}
                            fz={rem(24)}
                            fw={800}
                            fs='italic'>
                            {`${classification.group} / ${classification.subgroup}`}
                        </Text>
                        <Text
                            w={rem(120)}
                            fz={rem(24)}
                            fw={800}
                            fs='italic'>
                            {classification.version
                                ? `(${classification.version})`
                                : ''}
                        </Text>
                    </Group>
                </Box>,
            )}
        </Stack>
    </NumberedField>
