import { IconLayoutSidebarLeftExpand, IconLayoutSidebarRightExpand } from '@tabler/icons-react';
import { PathwaySettingsGeneral } from './PathwaySettingsGeneral';
import { PathwaySettingsMilestone } from './PathwaySettingsMilestone';
import { Text, Center, Group, Stack, Tabs } from '@mantine/core';
import { PathwaySettingsTask } from './PathwaySettingsTask';
import { ConfigurationTabs } from './ConfigurationTabs';
import { PathwayConfigurationProps } from './type';
import { PathwatInspectorTab } from './constant';
import { ConfigPanel } from './ConfigPanel';
import { RFC } from '@app/ui/type';


export const PathwayConfiguration : RFC<PathwayConfigurationProps> = ({
    isExpanded, onClick,
}) => {
    const handleClick = () : void => {
        onClick(!isExpanded);
    }

    if(!isExpanded)
        return (
            <Center
                style={{
                    cursor : 'pointer',
                }}>
                <IconLayoutSidebarRightExpand onClick={handleClick} />
            </Center>
        );

    return (
        <Stack>
            <Group
                justify='right'
                pr='sm'
                style={{
                    cursor : 'pointer',
                }}>
                <Text c='dimmed'>
                    <IconLayoutSidebarLeftExpand onClick={handleClick} />
                </Text>
            </Group>
            <Tabs
                defaultValue={PathwatInspectorTab.General}
                variant='outline'
                radius='md'>
                <ConfigurationTabs />
                <ConfigPanel id={PathwatInspectorTab.General}>
                    <PathwaySettingsGeneral />
                </ConfigPanel>
                <ConfigPanel id={PathwatInspectorTab.Milestones}>
                    <PathwaySettingsMilestone />
                </ConfigPanel>
                <ConfigPanel id={PathwatInspectorTab.Tasks}>
                    <PathwaySettingsTask />
                </ConfigPanel>
            </Tabs>
        </Stack>
    )
}
