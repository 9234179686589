import { NumberedField } from './NumberedField';
import { SectionTitleProps } from '../type';
import { Text, rem } from '@mantine/core';
import { RFC } from '@app/ui/type';


export const SectionTitle : RFC<SectionTitleProps> = ({
    text, fieldNumber,
}) =>
    <NumberedField fieldNumber={fieldNumber}>
        <Text
            tt='uppercase'
            fz={rem(20)}
            fw={600}>
            {text}
        </Text>
    </NumberedField>;
