import { PatentTransitionalPhrase } from '../enum/model/PatentTransitionalPhraseEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { PatentClaimModel } from '@app/type/model/PatentClaimModel';
import { PatentClaimType } from '../enum/model/PatentClaimTypeEnum';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';


export const DefaultPatentClaim : PatentClaimModel = {

    ...DefaultCoreModel,

    modelType          : ModelType.PatentClaim,
    transitionalPhrase : PatentTransitionalPhrase.Comprising,
    amendmentDetails   : '',
    referencedClaims   : [],
    hasBeenAmended     : false,
    definitionList     : [],
    limitationList     : [],
    claimNumber        : 1,
    claimType          : PatentClaimType.Independent,
    preamble           : '',
    claim              : '',
};


export const MakePatentClaim = (
    overrides : Partial<PatentClaimModel>,
) : PatentClaimModel => ({
    ...DefaultPatentClaim,
    ...overrides,
});
