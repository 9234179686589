import { FormEditCreateModel } from '@app/ui/form/FormEditCreateModel';
import { PathwayContext } from './PathwayContext';
import React, { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const PathwayCreate : RFC = () => {

    const { pathway, onChange } = useContext(PathwayContext);

    const { title, description } =  pathway;

    const handleChangeFieldText = (text : string, name ?: string) : void => {
        if (!name)
            throw new Error('Name and text are required');

        return onChange && onChange({
            ...pathway,
            [name] : text,
        });
    };

    return (
        <FormEditCreateModel
            onChange={handleChangeFieldText}
            description={description}
            label='Create a Pathway'
            title={title}
            value=''>
            {/* {'Additional Fields'} */}
        </FormEditCreateModel>
    );
};
