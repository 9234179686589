import { Group, Text, rem } from '@mantine/core';
import { PatentSectionProps } from '../type';
import { RFC } from '@app/ui/type';


export const NumberedField : RFC<PatentSectionProps> = ({
    fieldNumber, isTitle, children,
}) =>
    <Group
        wrap='nowrap'
        w='100%'
        gap={0}
        mb={isTitle ? rem(6) : 'lg'}>
        {fieldNumber &&
            <Text
                style={{
                    alignSelf : 'flex-start',
                }}
                fz={rem(20)}
                mr={rem(10)}>
                {`(${fieldNumber}) `}
            </Text>
        }
        {children}
    </Group>;
