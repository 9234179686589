import { TextInputDebounced } from '@app/ui/control/TextInputDebounced';
import { TextareaDebounced } from '@app/ui/control/TextareaDebounced';
import { ReactNode, useEffect, useRef, useState } from 'react';
import { CoreModel } from '@app/type/framework/core/CoreModel';
import { CA } from '@app/type/framework/core/CoreAction';
import { Box, FocusTrap } from '@mantine/core';
import { useDispatch } from '@app/client/hook';
import { EditFieldType, Props } from './type';
import { Action } from '@app/client/action';
import { RFC } from '@app/ui/type';


export const ClickToEdit : RFC<Props<CoreModel>> = ({
    size = 'md', children, onChange,
    model, value, type, name,
}) : ReactNode => {
    const [isEditing, setIsEditing] = useState(false);

    const refTextInput = useRef<HTMLInputElement>(null);
    const refTextArea  = useRef<HTMLTextAreaElement>(null);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isEditing)
            return;

        const currentRef =  type === EditFieldType.TextBox
            ? refTextInput.current
            : refTextArea.current;

        if (!currentRef)
            return;

        currentRef.focus();
        currentRef.select();
    }, [isEditing, type]);

    const handleToggleEdit = () : void => {
        if(!model)  return;

        setIsEditing(!isEditing);
    }

    const handleTextUpdate = (
        updatedText : string,
        fieldName  : string,
    ) : CA => {
        console.log('Debounced Text Change', updatedText, fieldName, value);

        if(updatedText === value) {
            console.log(`Skilling value is the same (${value})`);

            return dispatch(Action.noOp());
        }

        console.log('DO FOR REAL UPDATE');

        return onChange(updatedText, fieldName);
    };

    const handleTextInputBlur = (
        value : string,
        name  : string,
    ) : CA => {
        console.log('Closing Input field', {
            value, name,
        })

        setIsEditing(false);

        return handleTextUpdate(value, name);
    }

    if(isEditing)
        switch(type) {
            case EditFieldType.TextArea: return (
                <FocusTrap active={true}>
                    <TextareaDebounced
                        onChange={handleTextUpdate}
                        onBlur={handleTextUpdate}
                        value={value}
                        name={name}/>
                </FocusTrap>
            );

            case EditFieldType.TextBox: return (
                <FocusTrap active={true}>
                    <TextInputDebounced
                        onBlur={handleTextInputBlur}
                        onChange={handleTextUpdate}
                        value={value}
                        size={size}
                        name={name} />
                </FocusTrap>
            );
        }

    if(!model)
        return children;

    return (
        <Box
            onClick={handleToggleEdit}
            style={{
                cursor : 'pointer',
            }}>
            {children}
        </Box>
    );
};
