import { PathwatInspectorTab } from './constant';
import { TabHeader } from './TabHeader';
import { Tabs } from '@mantine/core';
import { RFC } from '@app/ui/type';
import React from 'react';


export const ConfigurationTabs : RFC = () =>
    <Tabs.List
        justify='center'>
        <TabHeader
            id={PathwatInspectorTab.General}
            label='General' />
        <TabHeader
            id={PathwatInspectorTab.Milestones}
            label='Milestones' />
        <TabHeader
            id={PathwatInspectorTab.Tasks}
            label='Tasks' />
    </Tabs.List>
