import { SectionIntClassification } from './SectionIntClassification';
import { SectionFormattedContent } from './SectionFormattedContent';
import { EditFieldType } from '@app/ui/form/ClickToEdit/type';
import { Button, Grid, Group, rem } from '@mantine/core';
import { PublicationNumber } from './PublicationNumber';
import { SectionClaimList } from './SectionClaimList';
import { SectionInventor } from './SectionInventor';
import { SectionMiniMeta } from './SectionMiniMeta';
import { SectionCentered } from './SectionCentered';
import { SectionDrawings } from './SectionDrawings';
import { HeaderMetadata } from './HeaderMetadata';
import { SectionAddress } from './SectionAddress';
import { NumberedField } from './NumberedField';
import { SectionFooter } from './SectionFooter';
import { useDispatch } from '@app/client/hook';
import { SectionTitle } from './SectionTitle';
import { JumboHeader } from './JumboHeader';
import { HeaderLatin } from './HeaderLatin';
import { SectionList } from './SectionList';
import { PatentContext } from '../Context';
import Barcode from 'react-barcode';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';
import { CA } from '@app/type/framework/core/CoreAction';
import { ClickToEdit } from '@app/ui/form/ClickToEdit';
import { Action } from '@app/client/action';


export const Content : RFC = () => {

    const { patent, onGeneratePatent } = useContext(PatentContext);

    const dispatch = useDispatch();

    // divide the section list into two equal parts, one
    // for each column. May be zero or more sections.

    const handleChangeText = (
        value : string, name : string,
    ) : CA => {

        console.log('handleChangeText', {
            value, name,
        });

        return dispatch(Action.noOp())
    }

    const formatDate = (date : string) : string =>
        new Date(date)
            .toLocaleDateString('en-US', {
                year  : 'numeric',
                month : 'long',
                day   : 'numeric',
            });

    return (
        <Grid
            gutter={0}
            style={{
                fontFamily : 'Times New Roman',
            }}>
            <Grid.Col
                span={12}>
                <Grid gutter={0}>
                    <Grid.Col span={2}>
                        <Button
                            aria-label='Generate'
                            onClick={onGeneratePatent}>
                            {'Generate'}
                        </Button>
                    </Grid.Col>
                    <Grid.Col span={10}>
                        <Group
                            justify='flex-end'
                            gap={0}>
                            <Barcode
                                value={patent.barcode}
                                font='Times New Roman'
                                height={38}
                                width={3} />
                        </Group>
                    </Grid.Col>
                </Grid>
            </Grid.Col>
            <Grid.Col
                span={12}
                h={rem(30)} />
            <Grid.Col
                span={12}
                style={{
                    borderBottom : '1px solid #000',
                }}>
                <Grid gutter={0}>
                    <Grid.Col span={7}>
                        <NumberedField
                            fieldNumber={19}
                            isTitle={true}>
                            <JumboHeader text={patent.country} />
                        </NumberedField>
                    </Grid.Col>
                    <Grid.Col span={5} />
                </Grid>
                <Grid gutter={0}>
                    <Grid.Col span={6}>
                        <NumberedField
                            fieldNumber={12}
                            isTitle={true}>
                            <ClickToEdit
                                type={EditFieldType.TextBox}
                                onChange={handleChangeText}
                                value={patent.subtitle}
                                model={patent}
                                size='xl'
                                name='subtitle'>
                                <JumboHeader text={patent.subtitle} />
                            </ClickToEdit>

                        </NumberedField>
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <NumberedField
                            fieldNumber={10}
                            isTitle={true}>
                            <ClickToEdit
                                type={EditFieldType.TextBox}
                                onChange={handleChangeText}
                                value='US 2022/736,546 A1'
                                model={patent}
                                size='xl'
                                name='publicationNumber'>
                                <HeaderMetadata
                                    value={<PublicationNumber publicationNumber={patent.publicationNumber} />}
                                    label='Pub.No.:' />
                            </ClickToEdit>
                        </NumberedField>
                    </Grid.Col>
                </Grid>
                <Grid gutter={0}>
                    <Grid.Col span={6}>
                        <HeaderLatin text={patent.latinPhrase} />
                    </Grid.Col>
                    <Grid.Col span={6}>
                        <NumberedField
                            isTitle={true}
                            fieldNumber={43}>
                            <HeaderMetadata
                                label='Pub. Date:'
                                value={formatDate(patent.publicationDate)} />
                        </NumberedField>
                    </Grid.Col>
                </Grid>
            </Grid.Col>
            <Grid.Col
                span={12}
                h={rem(30)} />
            <Grid.Col span={6}>
                <ClickToEdit
                    type={EditFieldType.TextBox}
                    onChange={handleChangeText}
                    value={patent.title}
                    model={patent}
                    size='xl'
                    name='title'>
                    <SectionTitle
                        fieldNumber={54}
                        text={patent.title} />
                </ClickToEdit>
                <SectionInventor
                    fieldNumber={75}
                    inventorList={patent.inventorList} />
                <SectionAddress
                    addressLine1={patent.addressLine1}
                    addressLine2={patent.addressLine2}
                    addressLine3={patent.addressLine3}
                    addressLine4={patent.addressLine4} />
                <SectionMiniMeta
                    label='Asignee:'
                    value={patent.assignee}
                    fieldNumber={73} />
                <SectionMiniMeta
                    label='App. No:.'
                    value={patent.applicationNumber?.serial}
                    fieldNumber={21} />
                <SectionMiniMeta
                    label='Filed:'
                    value={patent.filingDate}
                    fieldNumber={73} />
            </Grid.Col>
            <Grid.Col span={6}>
                <SectionCentered text='Publication Classification' />
                <SectionTitle
                    fieldNumber={52}
                    text='Int. Cl.' />
                <SectionIntClassification classificationList={patent.classificationList} />
                <SectionCentered
                    text='ABSTRACT'
                    fieldNumber={57} />
                <SectionFormattedContent text={patent.abstract} />
            </Grid.Col>
            <Grid.Col span={12}>
                <SectionDrawings />
            </Grid.Col>
            <Grid.Col span={6}>
                <SectionList sectionList={patent.sectionList} />
            </Grid.Col>
            <Grid.Col span={6}>
                <SectionClaimList claimList={patent.claimList} />
                <SectionFooter />
            </Grid.Col>
        </Grid>
    );
}
