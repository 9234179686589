import { RelationshipConfig } from '@app/type/model/RelationshipConfig';
import { PathwayContext } from '@app/ui/model/Pathway/PathwayContext';
import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { ModelChat } from '@app/ui/control/ModelChat';
import { RFC } from '@app/ui/type';
import { useContext } from 'react';


export const TabResearch : RFC = () => {

    const { pathway, chatList, isResearching } = useContext(PathwayContext);

    const relationshipConfig : RelationshipConfig = {
        [RT.BelongsTo ] : pathway,
    };

    return (
        <ModelChat
            relationshipConfig={relationshipConfig}
            isResearching={isResearching}
            belongsToModel={pathway}
            chatList={chatList} />
    );
}
