import { Badge, Group, Paper, Text, rem } from '@mantine/core';
import { MetricCriteriaList } from './MetricCriteriaList';
import { ReportMetricProps } from './type';
import { RFC } from '@app/ui/type';
import { FcInspection } from 'react-icons/fc';


export const ReportMetric : RFC<ReportMetricProps> = ({
    metric : {
        importanceScore, description, criteriaList, name,
    },
} ) =>
    <Paper
        mb='xl'
        shadow='xs'
        p='xl'>
        <Group justify='space-between'>
            <Group>
                <FcInspection size={26} />
                <Text
                    gradient={{ deg : 133, from : 'blue', to : 'cyan' }}
                    variant='gradient'
                    fz={rem(36)}>
                    {name}
                </Text>
            </Group>
            <Group wrap='nowrap'>
                <Text
                    fz={rem(14)}
                    fw={600}
                    c='dimmed'>
                    {'Importance'}
                </Text>
                <Badge
                    color={importanceScore > 8 ? 'red' : 'yellow'}
                    variant='filled'>
                    {importanceScore > 8 ? 'High' : 'Moderate'}
                </Badge>
            </Group>
        </Group>
        <Text>
            {description}
        </Text>
        <MetricCriteriaList criteriaList={criteriaList} />
    </Paper>
