import { Box, Center } from '@mantine/core';
import { StartButton } from './StartPatent';
import classes from './style.module.css';
import { RFC } from '@app/ui/type';
import React from 'react';


export const Footer : RFC = () =>
    <Box className={classes.footer}>
        <Center>
            <StartButton />
        </Center>

    </Box>
