import { CA } from '@app/type/framework/core/CoreAction';
import { WorkspaceContext } from '../WorkspaceContext';
import { Container, Paper, rem } from '@mantine/core';
import { generatePatentAction } from '../../action';
import { Patent } from '@app/ui/control/Patent';
import { useDispatch } from '@app/client/hook';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const TabClaims : RFC = () => {

    const dispatch = useDispatch();
    const { pathway } = useContext(WorkspaceContext);

    const _handleGeneratePatent = () : CA =>
        dispatch(generatePatentAction(pathway.hash));

    return (
        <Paper shadow='xl'>
            <Container
                fluid={true}
                maw={rem(1030)}>
                <Patent  />
            </Container>
        </Paper>
    )
};
