import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { PatentNumberModel } from '@app/type/model/PatentNumberModel';


export const DefaultPatentNumber : PatentNumberModel = {

    ...DefaultCoreModel,

    modelType : ModelType.Chat,

    countryCode : null,
    serial      : null,
    year        : null,
    kind        : null,

} as const;

export const MakePatentNumber = (
    overrides : Partial<PatentNumberModel>,
) : PatentNumberModel => ({
    ...DefaultPatentNumber,
    ...overrides,
    modelType : ModelType.PatentNumber,
});
