import { MockPatentModel } from '@app/constant/framework/MockPatentModel';
import { CA } from '@app/type/framework/core/CoreAction';
import { Content } from './components/Content';
import { useDispatch } from '@app/client/hook';
import { Action } from '@app/client/action';
import { PatentContext } from './Context';
import { RFC } from '@app/ui/type';


export const Patent : RFC = () => {

    const patent = MockPatentModel;

    const dispatch = useDispatch();

    const onGeneratePatent = () : CA => dispatch(Action.noOp())

    return (
        <PatentContext.Provider value={{ patent, onGeneratePatent }}>
            <Content />
        </PatentContext.Provider>
    );
};
