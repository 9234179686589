import { UserType } from '@app/constant/enum/model/UserTypeEnum';
import { PathwayModel } from '@app/type/model/PathwayModel';


export const getSubTitle = (
    userType : UserType,
    pathway : PathwayModel,
) : string =>
    userType === UserType.Business
        ? pathway.title || '[No Value]'
        : `${pathway.title} - ${pathway.description}`;


export const shouldShowTimeline = (userType : UserType) : boolean =>
    userType === UserType.Client
