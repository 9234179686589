import { Box, rem, Text } from '@mantine/core';
import { SectionAddressProps } from '../type';
import { RFC } from '@app/ui/type';


export const SectionAddress : RFC<SectionAddressProps> = ({
    addressLine1,
    addressLine2,
    addressLine3,
    addressLine4,
}) =>
    <Box
        mt='md'
        mb='md'
        ml={rem(50)}>
        <Text fz={rem(20)}>
            {'Correspondence Address:'}
        </Text>
        <Text
            tt='uppercase'
            fz={rem(19)}
            fw={600}>
            {addressLine1}
        </Text>
        <Text
            tt='uppercase'
            fz={rem(19)}
            fw={600}>
            {addressLine2}
        </Text>
        <Text
            tt='uppercase'
            fz={rem(19)}
            fw={600}>
            {addressLine3}
        </Text>
        <Text
            tt='uppercase'
            fz={rem(19)}
            fw={600}>
            {addressLine4}
        </Text>
    </Box>;

