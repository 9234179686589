export enum PatentTransitionalPhrase {
    ConsistingEssentiallyOf = 'ConsistingEssentiallyOf',
    CharacterizedBypm       = 'CharacterizedBypm',
    ConsistingOf            = 'ConsistingOf',
    Containing              = 'Containing',
    Comprising              = 'Comprising',
    Including               = 'Including',
    Wherein                 = 'Wherein',
    Whereby                 = 'Whereby',
    Having                  = 'Having',
    Being                   = 'Being',
}
