import { SimpleGrid, Card, Text, UnstyledButton, rem, Paper } from '@mantine/core';
import { ComplianceContext } from './ComplianceContext';
import classes from './ActionsGrid.module.css';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const ComplianceMetricGrid : RFC = () => {

    const { metricList } = useContext(ComplianceContext);

    return (
        <Card
            withBorder={false}
            radius='md'
            className={classes.card}>
            <SimpleGrid
                cols={3}
                mt='md'>
                {metricList.map((metric, index) =>
                    <Paper
                        ta='center'
                        withBorder={true}
                        style={{
                            borderRadius : rem(30),
                        }}
                        key={`${metric.hash}-${index}`}
                        shadow='md'>
                        <UnstyledButton p='lg'>
                            <Text
                                size={rem(40)}
                                ta='center'
                                fw={200}
                                mt={7}>
                                {metric.grade}
                            </Text>

                            <Text
                                size='xl'
                                ta='center'
                                fw={200}
                                mt={7}>
                                {metric.name}
                            </Text>
                        </UnstyledButton>
                    </Paper>,
                )}
            </SimpleGrid>
        </Card>
    );
}
