import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { PatentModel } from '@app/type/model/PatentModel';


export const DefaultPatent : PatentModel = {
    modelType : ModelType.Unknown, // fixme

    publicationNumber : null,
    applicationNumber : null,

    classificationList : [],
    usClassification   : '',
    publicationDate    : '',
    descriptionList    : [],
    inventorList       : [],
    addressLine1       : '',
    addressLine2       : '',
    addressLine3       : '',
    addressLine4       : '',
    description        : '',
    sectionList        : [],
    latinPhrase        : '',
    filingDate         : '',
    claimList          : [],
    createdAt          : null,
    updatedAt          : null,
    createdBy          : null,
    subtitle           : '',
    assignee           : '',
    abstract           : '',
    country            : '',
    barcode            : '',
    debug              : '',
    title              : '',
    hash               : '',
    id                 : null,
} as const;


export const MakePatent = (
    overrides : Partial<PatentModel>,
) : PatentModel => ({
    ...DefaultPatent,
    ...overrides,
    modelType : ModelType.Unknown, // fixme
});
