import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { PatentDescriptionModel } from '@app/type/model/PatentDescriptionModel';


export const DefaultPatentDescription : PatentDescriptionModel = {
    ...DefaultCoreModel,
    modelType : ModelType.PatentDescription,
};

export const MakePatentDescription = (
    overrides : Partial<PatentDescriptionModel>,
) : PatentDescriptionModel => ({
    ...DefaultPatentDescription,
    ...overrides,
});
