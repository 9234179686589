import { Box, CloseButton, Group, Stack, Text, rem } from '@mantine/core';
import { DefaultTask } from '@app/constant/default/DefaultTask';
import { IconName } from '@app/constant/enum/ui/IconNameEnum';
import { ComposeModel } from '@app/ui/control/ComposeModel';
import { ModelBoardContext } from './ModelBoardContext';
import { useContext, useState } from 'react';
import { Icon } from '@app/ui/static/Icon';
import classes from './style.module.css';
import { AddItemProps } from './type';
import { RFC } from '@app/ui/type';


export const AddItem : RFC<AddItemProps> = ({
    groupId,
}) => {
    const [ isComposing, setIsComposing ] = useState(false);

    const { onAddItem, renderCompose } = useContext(ModelBoardContext);

    const handleClick = () : void => {
        setIsComposing(true)
        onAddItem(groupId);
    }

    const handleCloseCompose = () : void =>
        setIsComposing(false)

    return (
        <Group>
            {!isComposing &&
                <Stack
                    className={classes.kanbanDropArea}
                    p='xl'
                    align='center'
                    style={{
                        borderRadius    : rem(24),
                        border          : '3px dashed rgba(0,0,0,0.1)',
                        backgroundColor : 'rgba(0,0,255,0.025)',
                    }}>
                    <Text
                        style={{
                            maxWidth : rem(200),
                        }}
                        fz={rem(22)}
                        ta='center'>
                        {'Add a Task or Drag and Drop One Here'}
                    </Text>
                    <Icon
                        onClick={handleClick}
                        iconName={IconName.Plus} />
                </Stack>

            }
            {isComposing &&
                <Box>
                    <Group justify='right'>
                        <CloseButton onClick={handleCloseCompose} />
                    </Group>
                    <ComposeModel
                        relationshipConfig={{}}
                        composingModel={DefaultTask}
                        renderCompose={renderCompose(groupId)} />
                </Box>
            }
        </Group>
    );
}
