import { LayoutModelDetail } from '@app/ui/template/LayoutModelDetail';
import { TriageBadge } from '@app/ui/control/TriageBadge';
import { MilestoneMetadata } from './MilestoneMetadata';
import { Container } from '@mantine/core';
import { ViewProps } from './type';
import { RFC } from '@app/ui/type';
import { TriageLevelType } from '@app/constant/enum/ui/TriageLevelTypeEnum';


export const MilestoneView : RFC<ViewProps> = ({
    milestone,
}) => {
    return (
        <Container
            fluid={true}
            pl='xl'
            pr='xl'>
            <LayoutModelDetail
                titleIcon={<TriageBadge triageLevel={TriageLevelType.Low} /> }
                description={milestone.description || '[no description]'}
                title={milestone.title || 'Untitled'}>
                <MilestoneMetadata
                    showManageControls={false}
                    milestone={milestone} />
            </LayoutModelDetail>
        </Container>
    );
};
