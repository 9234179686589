import { BusinessClientToggle } from '@app/ui/control/BusinessClientToggle';
import { TextareaDebounced } from '@app/ui/control/TextareaDebounced';
import { PathwayContext } from '@app/ui/model/Pathway/PathwayContext';
import React, { ReactNode, useContext, useState } from 'react';
import { CA } from '@app/type/framework/core/CoreAction';
import { Button, Group } from '@mantine/core';
import { Action } from '@app/client/action';
import { useDispatch } from 'react-redux';


export const PathwaySettingsGeneral = () : ReactNode => {

    const { pathway } = useContext(PathwayContext);
    const dispatch = useDispatch();

    const [userMode, setUserMode] = useState('Business');

    const handleChangeUserMode = (value : string) : void => {
        setUserMode(value);
    }

    const handleChangeText = (
        value : string,
        name  : string,
    ) : void => {
        const pendingPathway = {
            ...pathway,
            [name] : value,
        };

        dispatch(Action.updateStashModelSync({
            model : pendingPathway,
        }));
    };

    const handlePublishPathway = () : CA =>
        dispatch(Action.publishPathway(pathway));

    const pathwayFieldInputList = [

        /* eslint-disable max-len */

        { group : 'Business', label : 'Title',       name : 'title',       description : 'Internal Name for the Pathway' },
        { group : 'Business', label : 'Description', name : 'description', description : 'Internal Description for the Pathway' },

        { group : 'Business', label : 'Process Description (Business)', name : 'processDescriptionBusiness', description : 'Describe the pathway process on the business side' },
        { group : 'Client',   label : 'Process Description (Client)',   name : 'processDescriptionClient'  , description : 'Describe the pathway process for the client' },
        { group : 'Business', label : 'Stakeholders (Business)',        name : 'stakeholdersBusiness'      , description : 'Describe the stakeholders the client needs to be concerned with' },
        { group : 'Client',   label : 'Stakeholders (Client)',          name : 'stakeholdersClient'        , description : 'Describe the stakeholders the business needs to be concerned with' },
        { group : 'Business', label : 'Participant (Business)',         name : 'participantBusiness'       , description : 'Describe the person assigned to the business side of the client pathway' },
        { group : 'Client',   label : 'Participant (Client)',           name : 'participantClient'         , description : 'Describe who the ideal client is for this pathway' },
        { group : 'Business', label : 'Deadline (Business)',            name : 'deadlineBusiness'          , description : 'Pathway deadline visible to the business' },
        { group : 'Client',   label : 'Deadline (Client)',              name : 'deadlineClient'            , description : 'Pathway deadline visible to the client' },
        { group : 'Business', label : 'Title (Business)',               name : 'titleBusiness'             , description : 'Pathway title as seen by the business' },
        { group : 'Client',   label : 'Title (Client)',                 name : 'titleClient'               , description : 'Pathway title as seen by the client' },
        { group : 'Business', label : 'Goal (Business)',                name : 'goalBusiness'              , description : 'Describe the goal of the pathway from the business perspective' },
        { group : 'Client',   label : 'Goal (Client)',                  name : 'goalClient'                , description : 'Describe the goal of the pathway from the client perspective' },


        { group : 'Business',   label : 'priorityBusiness',          name : 'priorityBusiness'          , description : 'Description of priorityBusiness'          },
        { group : 'Client',     label : 'priorityClient',            name : 'priorityClient'            , description : 'Description of priorityClient'            },
        { group : 'Business',   label : 'budgetBusiness',            name : 'budgetBusiness'            , description : 'Description of budgetBusiness'            },
        { group : 'Client',     label : 'budgetClient',              name : 'budgetClient'              , description : 'Description of budgetClient'              },


        /* eslint-enable max-len */
    ];

    return (
        <form>
            <Group justify='center'>
                <Button
                    aria-label='Publish Pathway'
                    onClick={handlePublishPathway}>
                    {'Publish Pathway'}
                </Button>
            </Group>
            <BusinessClientToggle
                onChange={handleChangeUserMode}
                value={userMode} />
            {pathwayFieldInputList
                .filter(item => item.group === userMode)
                .map(({ label, name }) =>
                    <TextareaDebounced
                        value={pathway[name] as string || ''}
                        onChange={handleChangeText}
                        onBlur={handleChangeText}
                        label={label}
                        name={name}
                        key={name} />,
                )}
        </form>
    );
};
