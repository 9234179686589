import { Card, Text, Badge, Group, Avatar, useMantineTheme, rem, Box } from '@mantine/core';
import { ControlModeType } from '@app/constant/enum/ui/ControlModeTypeEnum';
import { IconBookmark, IconHeart, IconShare } from '@tabler/icons-react';
import { PathwayContext } from '@app/ui/model/Pathway/PathwayContext';
import { ReactNode, useContext, useRef } from 'react';
import { TaskModel } from '@app/type/model/TaskModel';
import TaskImage04 from '@app/asset/task-04.png';
import { useDispatch } from '@app/client/hook';
import { type BoardItemProps } from './type';
import { Action } from '@app/client/action';
import { Task } from '@app/ui/model/Task';
import classes from './style.module.css';
import { useFullscreen } from 'ahooks';
import { RFC } from '@app/ui/type';
import Image from 'next/image';


export const BoardItem : RFC<BoardItemProps> = ({
    model, hoverEffect = true,
}) : ReactNode => {

    const theme = useMantineTheme();
    const dispatch = useDispatch();

    const { pathway } = useContext(PathwayContext);

    const ref = useRef(null);

    const [isFullscreen, { enterFullscreen }] = useFullscreen(ref);

    const handleClick = () : void => {
        enterFullscreen();
        dispatch(Action.openTask(model.hash));
    };

    const scale = 3.3;

    return (
        <Card
            className={
                !isFullscreen && hoverEffect
                    ? classes.hoverPanel
                    : ''
            }
            style={{
                marginBottom : rem(40),
                boxShadow    : '0px 3px 12px 2px rgba(0,0,0,0.06 )',
                cursor       : isFullscreen ? 'pointer' : 'grab',
            }}
            onClick={handleClick}
            withBorder={true}
            radius='sm'
            ref={ref}>
            {isFullscreen &&
                <Task
                    mode={ControlModeType.View}
                    value={model as TaskModel}
                    pathwayMilestoneList={[]}
                    showRowContent={false}
                    pathway={pathway} />
            }
            {!isFullscreen &&
                <Box>
                    <Card.Section>
                        <Image
                            src={TaskImage04.src}
                            alt='Task'
                            height={782 / scale}
                            width={1368 / scale} />
                    </Card.Section>
                    <Group
                        justify='space-between'
                        pb='xs'>
                        <Badge
                            mt='xs'
                            variant='light'>
                            {'TASK'}
                        </Badge>
                        <Badge
                            mt='xs'
                            variant='outline'>
                            {'Client'}
                        </Badge>
                    </Group>
                    <Text
                        pt='sm'
                        fw={400}
                        fz={rem(20)}
                        component='a'>
                        {model.title}
                    </Text>
                    <Text
                        fz='sm'
                        mb='sm'
                        pt='xs'
                        c='#666666'
                        lineClamp={4}>
                        {model.description}
                    </Text>
                    <Group
                        wrap='nowrap'
                        justify='space-between'
                        pt='xs'>
                        <Group>
                            <Avatar
                                src='https://raw.githubusercontent.com/mantinedev/mantine/master/.demo/avatars/avatar-1.png'
                                size={24}
                                radius='xl'/>
                            <Text
                                fz='sm'
                                inline={true}>
                                {'John Doe'}
                            </Text>
                        </Group>
                        <Group>
                            <IconHeart
                                style={{ width : rem(16), height : rem(16) }}
                                color={theme.colors.red[6]} />
                            <IconBookmark
                                style={{ width : rem(16), height : rem(16) }}
                                color={theme.colors.yellow[7]}/>
                            <IconShare
                                style={{ width : rem(16), height : rem(16) }}
                                color={theme.colors.blue[6]} />
                        </Group>
                    </Group>
                </Box>
            }
        </Card>
    );
}
