import { RT } from '@app/constant/enum/model/RelationshipTypeEnum';
import { ComplianceContext } from './ComplianceContext';
import { ComplianceContent } from './ComplianceContent';
import { ComplianceMockData } from './constant';
import { RelationshipConfig } from '@app/type/model/RelationshipConfig';
import { RFC } from '@app/ui/type';
import { Props } from './type';


export const Compliance : RFC<Props> = ({ model }) => {

    const relationshipConfig : RelationshipConfig = {
        [RT.RelatedTo ] : model,
    };

    const MockData = ComplianceMockData;

    return (
        <ComplianceContext.Provider
            value={{
                metricList : MockData,
                relationshipConfig,
                model,
            }}>
            <ComplianceContent />
        </ComplianceContext.Provider>
    );
};
