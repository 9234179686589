import { ModelType } from '@app/constant/enum/model/ModelTypeEnum';
import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { PatentLimitationModel } from '@app/type/model/PatentLimitationModel';


export const MakePatentLimitation = (
    overrides : Partial<PatentLimitationModel>,
) : PatentLimitationModel => ({
    ...DefaultPatentLimitation,
    ...overrides,
});

export const DefaultPatentLimitation : PatentLimitationModel = {
    ...DefaultCoreModel,
    modelType : ModelType.PatentLimitation,
};
