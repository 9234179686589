import { ModelBoardContext } from './ModelBoardContext';
import { BoardItemGroup } from './BoardItemGroup';
import { Grid } from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import { TaskStatusType } from '@app/constant/enum/model/TaskStatusTypeEnum';


export const ModelBoard : RFC<Props> = ({
    modelList, onAddItem, renderCompose,
}) =>
    <ModelBoardContext.Provider
        value={{
            renderCompose,
            modelList,
            onAddItem,
        }}>
        <Grid>
            <Grid.Col span={4}>
                <BoardItemGroup
                    color='cyan'
                    groupId={TaskStatusType.Pending}
                    label='To Do' />
            </Grid.Col>
            <Grid.Col span={4}>
                <BoardItemGroup
                    color='blue'
                    groupId={TaskStatusType.InProgress}
                    label='In Progress' />
            </Grid.Col>
            <Grid.Col span={4}>
                <BoardItemGroup
                    color='green'
                    groupId={TaskStatusType.Complete}
                    label='Done' />
            </Grid.Col>
        </Grid>
    </ModelBoardContext.Provider>;
