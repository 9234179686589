import { DefaultPathway } from '@app/constant/default/DefaultPathway';

import React from 'react';
import { PathwayContextType } from './type';


export const PathwayContext = React.createContext<PathwayContextType>({
    pathwayMilestoneList : [],
    showManageControls   : false,
    selectedAssetHash    : null,
    pathwayTaskList      : [],
    isResearching        : false,
    document             : null,
    assetList            : [],
    chatList             : [],
    pathway              : DefaultPathway,
});
