import { Tabs, Text } from '@mantine/core';
import { TabProps } from './type';
import { RFC } from '@app/ui/type';
import React from 'react';


export const TabHeader : RFC<TabProps> = ({
    label, id ,
}) =>
    <Tabs.Tab value={id}>
        <Text>
            {label}
        </Text>
    </Tabs.Tab>
