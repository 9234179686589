import { ScreenSize } from '@app/constant/framework/ScreenSize';
// import { UtilityUI } from '@app/ui/UtilityUI';
import { Grid, Paper} from '@mantine/core';
import { RFC } from '@app/ui/type';
import { Props } from './type';
import { UtilityUI } from '../../UtilityUI';


export const ResponsiveTester : RFC<Props> = ({
    screenSize = ScreenSize.Desktop,
    children,
}) =>
    <Paper
        withBorder={false}>
        <Grid gutter={0} >
            <Grid.Col span={UtilityUI.screenSizeCol(screenSize, 4, 2, 0)} />
            <Grid.Col
                span={UtilityUI.screenSizeCol(screenSize, 4, 8, 12)}
                pl='xs'>
                {children}
            </Grid.Col>
        </Grid>
    </Paper>;
