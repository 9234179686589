import { CoreItemRow } from '@app/ui/layout/CoreItemRow';
import { PathwayContext } from './PathwayContext';
import { Badge, Box, Text } from '@mantine/core';
import { PathwayRowProps } from './type';
import { ColorLookup } from './constant';
import { useContext } from 'react';
import { RFC } from '@app/ui/type';


export const PathwayRow : RFC<PathwayRowProps> = ({
    showRowContent,
}) => {

    const { pathway } = useContext(PathwayContext);

    return (
        <CoreItemRow
            model={pathway}
            rightSection={
                <Badge
                    p='sm'
                    variant='outline'
                    color={ColorLookup[pathway.status]}>
                    <Text fw={600}>
                        {pathway.status}
                    </Text>
                </Badge>
            }>
            {showRowContent && (
                <Box
                    style={{
                        paddingLeft : '48px',
                    }}>
                    <Text>
                        {pathway.publishedAt}
                    </Text>
                    <Text>
                        {pathway.publishedBy}
                    </Text>
                </Box>
            )}
        </CoreItemRow>
    );
};

