import { PathwayBuilderMain } from './PathwayConfig/PathwayBuilderMain';
import { PathwayConfiguration } from './PathwayConfig';
import { PathwayBuilderView } from '../type';
import classes from '../Pathway.module.css';
import { Box, Flex } from '@mantine/core';
import { RFC } from '@app/ui/type';
import React from 'react';


export const PathwayBuilder : RFC<PathwayBuilderView> = ({
    selectedMilestoneHash,
    responsiveScreenType,
    userType,
}) => {
    const [isExpanded, setIsExpanded] = React.useState(true);

    const handleClick = (expand : boolean) : void =>
        setIsExpanded(expand);

    return (
        <Flex>
            <Box className={classes.mainContent}>
                {'asdfasdf'}
                <PathwayBuilderMain
                    selectedMilestoneHash={selectedMilestoneHash}
                    responsiveScreenType={responsiveScreenType}
                    userType={userType} />
            </Box>
            <Box
                w={isExpanded ? 400 : 40}
                className={classes.sidebarSettings}>
                <PathwayConfiguration
                    onClick={handleClick}
                    isExpanded={isExpanded} />
            </Box>
        </Flex>
    );
}
