import { DefaultCoreModel } from '@app/constant/default/DefaultCoreModel';
import { ComplianceContextType } from './type';
import React from 'react';


export const ComplianceContext = React.createContext<ComplianceContextType>({
    relationshipConfig : {},
    metricList         : [],
    model              : DefaultCoreModel,
});
