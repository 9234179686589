import { Group, Text, rem } from '@mantine/core';
import { PublicationNumberProps } from '../type';
import { RFC } from '@app/ui/type';


export const PublicationNumber : RFC<PublicationNumberProps> = ({
    publicationNumber,
}) => {
    if(!publicationNumber)
        return (
            <Group
                wrap='nowrap'
                gap={0}>
                <Text
                    mr='xs'
                    fz={rem(32)}
                    fw={700}>
                    {'[No Value]'}
                </Text>
            </Group>
        );

    const addCommas = (integerString : string | null) : string =>
        integerString
            ? parseInt(integerString).toLocaleString()
            : '000,000'

    const { year, serial, kind, countryCode  } = publicationNumber;

    return (
        <Group
            wrap='nowrap'
            gap={0}>
            <Text
                fz={rem(32)}
                fw={700}
                mr='xs'>
                {`${countryCode} ${year}/${addCommas(serial)} ${kind}`}
            </Text>
        </Group>
    );
}


